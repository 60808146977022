/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { SoundOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Dropdown,
    Menu,
    Modal,
    Input,
    message,
    Avatar,
    Badge,
    Button,
    Table,
    Pagination,
} from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import screenfull from 'screenfull'
import { inject, observer } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'
// import { isAuthenticated } from '../../utils/Session'
import Action from '@/redux/Action'
import API from "../../api/api"
import redux from '../../redux/Store'
import md5 from "js-md5"
import CustomMenu from "../CustomMenu/index";
import Utils from '@/utils/utils';
import QRCode from 'qrcode.react'
const FormItem = Form.Item;

//withRouter一定要写在前面，不然路由变化不会反映到props中去
@withRouter @inject('appStore') @observer
@Form.create()
class HeaderBar extends React.Component {
	state = {
		icon: 'arrows-alt',
		modalVisible: false,
		modalTitle: '',
		adminMsg: {}, // 管理员信息数据
		form: {},
		btnLoading: false,
		count: 0,
		pageNo: 0,
		pageSize: 10,
		totalCount: 0,
		platInfo: redux.getState().platInfo
	}

	// 计算属性 获取语言
	get locale() {
		return redux.getState().language
	}

	/**
	 * 计算属性 获取logo
	 */
	getLogoUrl = async () => {
		// 获取logo
		let platInfo = redux.getState().platInfo;
		if(!platInfo || platInfo.logo === "null" || platInfo.logo === null) {
			this.setState({
				logoBlob: require("@/assets/img/logo.png")
			})
		} else {
			let url = await Utils.previewImage({
				url: platInfo.logo
			})
			this.setState({
				logoBlob: url
			})
		}
	}

	/**
	 * 计算属性 获取平台名称
	 */
	get platName() {
		let platInfo = redux.getState().platInfo
		if(window.constants.isMergePlatform) {
			if(platInfo) {
				return platInfo.name
			} else {
				return '筑泰防务'
			}
		} else {
			if(platInfo && platInfo.abbreviation) {
				return platInfo.abbreviation
			} else {
				return '筑泰防务'
			}
		}
	}

	async componentDidMount () {
		await this.getFindAdmin();
		screenfull.onchange(() => {
			this.setState({
				icon: screenfull.isFullscreen ? 'shrink' : 'arrows-alt'
			})
		})
		redux.dispatch(await Action.getPlatInfo());
		// 未读消息个数
		this.getCountNoticeMessage()

		this.getLogoUrl();
		redux.subscribe(() => {
            if(this.state.platInfo !== redux.getState().platInfo) {
				this.setState({
                    platInfo: redux.getState().platInfo
                }, () => {
                    this.getLogoUrl();
                })
            }
        })
	}

	// 未读消息个数
	getCountNoticeMessage = async () => {
		let res = await API.msg.countNoticeMessage()
		this.setState({
		  count: res.content
		})
	} 

	// 获取用户基本信息
	getFindAdmin = async () => {
		let res = await JSON.parse(sessionStorage.getItem("adminInfo"))
		this.setState({
			adminMsg: res,
			form: res
		})
	}

	componentWillUnmount () {
		screenfull.off('change')
	}

	toggle = () => {
		this.props.onToggle()
	}

	screenfullToggle = () => {
		if (screenfull.isEnabled) {
			screenfull.toggle()
		}
	}

	getfresh() {
		let fresh = redux.getState().fresh
		if (fresh) {
			
		}
	}

	logout = async () => {
		let res = await API.logout();
		if(res.code === 200) {
			// 清除token
			sessionStorage.removeItem("token");
			this.props.appStore.toggleLogin(false);

			if(window.constants.isMergePlatform) {
				window.location = "/page/login";
			} else {
				this.props.history.push("/page/login");
			}
		}
	}

	// 语言切换
	languageChange = () => {
		let lang = this.locale === 'zh' ? 'en' : 'zh';
		this.setState({
			locale: lang
		})
		localStorage.setItem('locale', lang);
		redux.dispatch({ type: lang, payload: lang })
	}

	// 保存
	handleSubmit = (e) => {
		this.props.form.validateFields(async (err, values) => {
			if(!err){
				this.setState({btnLoading: true})
				if (this.state.modalTitle === 'ChangeBasicInformation') {
					let params = {
						id: this.state.form.id,
						...values
					}
					await API.admin.update(params);
				}else{
					// values.confirmPassword = md5(values.confirmPassword);
					delete values.confirmPassword;
						values.newpwd = md5(values.newpwd);
						values.oldpwd = md5(values.oldpwd);
						let params = {
							// id: this.state.form.id,
							...values
						}
						await API.admin.updatePassword(params)
				}
				// 国际化调API
				this.setState({btnLoading: false})
				const { intl: { formatMessage } } = this.props
				const successTips = formatMessage({id:'SuccessfulOperation'});
				message.success(successTips);
				this.setState({
					modalVisible: false
				})
				this.logout()
				
			}
		});
	}

	// 打开弹框
	showModal = (e) => {
		let title = ''
		if (e === 'personal') {
			// 获取用户基本信息
			this.getFindAdmin()
			title = 'ChangeBasicInformation'
		} else {
			title = 'ChangePwd'
		}
		this.setState({
			modalVisible: true,
			modalTitle: title
		})
	}

	/**
	 * 点击取消按钮
	 */
	handleCancel = (e) => {
		this.setState({
			modalVisible: false,
			btnLoading: false
		})
		this.props.form.resetFields()
	}

	/**
	 * 验证确认密码是否正确
	 */
	compareToFirstPassword = (rule, value, callback) => {
		const form = this.props.form;
		if (value && value !== form.getFieldValue('newpwd')) {
			callback(<FormattedMessage id="TwoPasswordDiff" />);
		} else {
			callback();
		}
	}

	/**
	 * 验证密码格式
	 */
	 validatorPwd = (rule, value, callback) => {
		if (value && (!(window.constants.accountReg.test(value)) || value === this.state.loginName)) {
			callback(<FormattedMessage id="PwdRule" />);
		} else {
			callback();
		}
	}

	async findNoticeMessageList(){
		let res = await API.msg.findNoticeMessageList({
		  pageNo: this.state.pageNo,
		  pageSize: this.state.pageSize,
		});
		this.setState({
		  msgVisible: true,
		  loading: false,
		  msgData: res.content,
		  totalCount: res.totalElements
		})
	  }
	  // 打开消息弹框
	  showCountModal = async () => {
		this.findNoticeMessageList()
	  }
	  // 关闭消息弹框
	  handleMsgCancel = async () => {
		this.setState({
		  count: 0,
		  msgVisible: false
		})
		await API.msg.updateNoReadMsgList();
	  }

	  changeCanvasToPic = () => {
		const canvasImg = document.getElementById('qrCode'); // 获取canvas类型的二维码
		const img = new Image();
		img.src = canvasImg.toDataURL('image/png'); // 将canvas对象转换为图片的data url
		const downLink = document.getElementById('down_link');
		downLink.href = img.src;
		downLink.download = '二维码'; // 图片name
	  }

	render () {
		const { appStore, location } = this.props
		const notLogin = (
			<div>
				<Link to={{pathname: '/page/login', state: {from: location}}} style={{color: 'rgba(0, 0, 0, 0.65)'}}>登录</Link>&nbsp;
				<img src={require('../../assets/img/defaultUser.jpg')} alt=""/>
			</div>
		)
		const menu = (
			<Menu className='menu'>
				<Menu.ItemGroup title={(<FormattedMessage id="UserCenter" />)} className='menu-group'>
					<Menu.Item key="usermenu1"><FormattedMessage id="Hello" /> - {this.state.adminMsg ? this.state.adminMsg.realName : ''}</Menu.Item>
					{/* 账号资料 */}
					<Menu.Item key="usermenu2" onClick={() => this.showModal('personal')}><span><FormattedMessage id="AccountInfo"/></span></Menu.Item>
					{/* 修改密码 */}
					<Menu.Item key="usermenu3" onClick={() => this.showModal('password')}><span><FormattedMessage id="ChangePwd"/></span></Menu.Item>
					<Menu.Item key="usermenu4" onClick={this.logout}><span><FormattedMessage id="Logout" /></span></Menu.Item>
				</Menu.ItemGroup>
			</Menu>
		)
		const login = (
			<Dropdown overlay={menu}>
				<Avatar icon={<UserOutlined />} />
			</Dropdown>
		)

		// 未读消息提醒
		const columns = [{
			title: (<FormattedMessage id="MessageContents" />),  // 消息内容
			width: 450,
			align: 'center',
			textWrap: 'word-break',
			dataIndex: 'content',
			render: (text, record) => (
			  <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
				{text}
			  </div>
			),
		},{
			title: (<FormattedMessage id="CreationTime" />),  // 创建时间
			align: 'center',
			width: 150,
			dataIndex: 'createtime'
		},{
		  title: (<FormattedMessage id="State" />),  // 状态
		  align: 'center',
		  dataIndex: 'status',
		  width: 100,
		  render: (id, row, index) => (
			<div>  
			  { row.status === 0 ? (<div style={{ color: 'red'}}><FormattedMessage id="UnRead"/></div>) : (<FormattedMessage id="Read"/>)}
			</div>
		  )
		}]
		const { getFieldDecorator } = this.props.form;

		return (
            <div id='headerbar' className="header_bar">
				<div className="logo_box">
					{ this.state.logoBlob ? <img src={this.state.logoBlob} alt="" /> : null}
					<div className="plat-name">
						<span>{this.platName}</span>
						{ window.constants.isMergePlatform ? <span className='plat-name-txt'>保物通</span> : null }
					</div>
				</div>
				{ this.props.hasMenu ? <div className='header_menu'><CustomMenu /></div> : null }
				
				{ this.props.hasMenu ? <div className='header_right'><ul className='header-ul'>
					{/* <li><Icon type={icon} onClick={this.screenfullToggle}/></li> */}
					{/* <li>
						<Button size="small" onClick={this.languageChange}>{this.locale==="zh" ? "English": "中文"}</Button>
					</li> */}
					<li onClick={this.showCountModal}>
						<Badge count={this.state.count} overflowCount={99} style={{marginLeft: 17}}>
							<SoundOutlined style={{fontSize: 20}} />
						</Badge>
					</li>
					<li>
						{appStore.isLogin ? login : notLogin}
					</li>
				</ul></div> : null}

				{/* 设置权限修改时 */}
				{this.fresh}

				{/* 修改资料/修改密码弹框 */}
				<Modal
				visible={this.state.modalVisible}
				title={(<FormattedMessage id={this.state.modalTitle} />)}
				onOk={this.handleSubmit}
				confirmLoading={this.state.btnLoading}
				onCancel={this.handleCancel}>
					<Form layout="horizontal" ref="myForm">
					{this.state.modalTitle === 'ChangeBasicInformation' ? <div>
							{/* <FormItem label={(<FormattedMessage id="Account" />)} {...window.constants.formItemLayout}>
							{getFieldDecorator('loginName', {
							initialValue: this.state.form ? this.state.form.loginName : ''
							})(<Input disabled/>)}
							</FormItem> */}
							<FormItem label={(<FormattedMessage id="AdministratorsName" />)} {...window.constants.formItemLayout}>
							{getFieldDecorator('realName', {
								rules: [{ required: true, whitespace: true, message: (<FormattedMessage id="InputItem" values={{name: (<FormattedMessage id="AdministratorsName" />)}} />) }],
								initialValue: this.state.form ? this.state.form.realName : ''
							})(<Input maxLength={32}/>)}
							</FormItem>
							<FormItem label={(<FormattedMessage id="Mailbox" />)} {...window.constants.formItemLayout}>
							{getFieldDecorator('email', {
								rules: [{pattern: window.constants.emailReg, message: (<FormattedMessage id="InputCorrect" values={{name: (<FormattedMessage id="Mailbox" />)}} />)}],
								initialValue: this.state.form ? this.state.form.email : ''
							})(<Input maxLength={32}/>)}
							</FormItem>
							<FormItem label={(<FormattedMessage id="PhoneNum" />)} {...window.constants.formItemLayout}>
							{getFieldDecorator('phoneNum', {
								rules: [
									{ required: true, whitespace: true, message: (<FormattedMessage id="InputItem" values={{name: (<FormattedMessage id="PhoneNum" />)}} />) },
									{ pattern: window.constants.mobilePhoneReg, message: (<FormattedMessage id="InputCorrect" values={{name: (<FormattedMessage id="PhoneNum" />)}} />)}
								],
								initialValue: this.state.form ? this.state.form.phoneNum : ''
							})(<Input maxLength={11}/>)}
							</FormItem>
						</div> : (
						<div>
							{/* 旧密码 */}
							<FormItem label={(<FormattedMessage id="OriginalPwd" />)} {...window.constants.formItemLayout}>
							{getFieldDecorator('oldpwd', {
								rules: [{ required: true, whitespace: true, message: (<FormattedMessage id="InputItem" values={{name: (<FormattedMessage id="CurPwd" />)}} />) }],
							})(
								<Input maxLength={32} type="password"/>
							)}
							</FormItem>
							{/* 新密码 */}
							<FormItem label={(<FormattedMessage id="NewPwd" />)} {...window.constants.formItemLayout}>
							{getFieldDecorator('newpwd', {
								rules: [{ required: true, whitespace: true, message: (<FormattedMessage id="InputItem" values={{name: (<FormattedMessage id="NewPwd" />)}} />) },
								{validator: this.validatorPwd}]
							})(
								<Input maxLength={32} type="password"/>
							)}
							</FormItem>
							{/* 确认密码 */}
							<FormItem label={(<FormattedMessage id="PleaseConfirmPassword" />)} {...window.constants.formItemLayout}>
							{getFieldDecorator('confirmPassword', {
								rules: [{ required: true, whitespace: true, message: (<FormattedMessage id="InputItem" values={{name: (<FormattedMessage id="ConfirmPwd" />)}} />) },
								 {
									validator: this.compareToFirstPassword,
								}],
								initialValue: this.state.form.confirmPassword
							})(
								<Input maxLength={32} type="password"/>
							)}
							</FormItem>
						</div>
						)
						}
					</Form>
				</Modal>

				{/* 消息弹框 */}
				<Modal
				visible={this.state.msgVisible}
				title={(<FormattedMessage id='PlatformMessage' />)}
				onCancel={this.handleMsgCancel}
				width={1000}
				footer={[<Button key="back" type="primary" onClick={this.handleMsgCancel}><FormattedMessage id="OK"/></Button>]}>
				{/* 表格 */}
				<Table rowKey={record => record.id} loading={this.state.loading} dataSource={this.state.msgData} pagination={false} columns={ columns}/>
				<Pagination showSizeChanger pageSize={this.state.pageSize} current={this.state.pageNo+1} total={this.state.totalCount}
				onChange={(page, pageSize) => Utils.onPageChange(this, page, pageSize)} onShowSizeChange={(page, pageSize) => Utils.onPageChange(this, page, pageSize)} />
				</Modal>
			</div>
        );
	}
}

export default injectIntl(HeaderBar)