import React from 'react'
import { Layout } from 'antd'
import HeaderBar from '@/components/HeaderBar'
import License from './license';

const { Header, Content } = Layout

class Index extends React.Component{
	state = {
		collapsed: false
	}

	render() {
		return (
			<div id='page'>
				<Layout>
					{/* <Header>
						<HeaderBar hasMenu={false} />
					</Header> */}
					<Content className="wide content-main">
						<div style={{padding: '16px 76px', marginTop: 15, backgroundColor: '#fff'}}>
							<License></License>
						</div>
					</Content>
				</Layout>
			</div>
		);
	}
}
export default Index