import React from 'react'
import { injectIntl } from 'react-intl';
import { Map, Markers, InfoWindow, Polygon  } from 'react-amap';
import GPS from "@/utils/map_translate.js"
import Utils from '@/utils/utils'
import {Icon} from 'antd'
// 查看运行轨迹
// import RunningTrack from "@/routes_emm/Device/DeviceManage/runningTrack"
import MapTrack from "../../../Patrol/map.js"


/**
 * 自动缩放地图到合适的视野级别
 * @param {*} props 
 */
const FitView = (props) => {
    // props.__ele__;
    // props.__map__;
    // your code here
    props.__map__.setFitView();
    return null;
};

class MapIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            markers: [],
            center: {
                longitude: 114.06667,
                latitude: 22.61667
            },
            infoVisible: false,
			infoPos: {},
            mapVisible: false,
            selectedRowKey: "",
            GEOJSON: [],
            fullscreen: false,
        }
        this.markersEvents = {
            click: async (MapsOption, marker) => {
                // console.log(marker.getExtData())
                let data = marker.getExtData()
                this.setState({
                    infoVisible: true,
                    infoPos: {
                        ...data,
                        address: await Utils.getAddress(window.AMap, data.position.longitude, data.position.latitude),
                    }
                })
            }
        }
        this.infoWindowEvents = {
            close: () => {
                this.setState({
                    infoVisible: false
                })
            }
        }
        this.PolygonEvents = {
            // mouseover: (e) => {
            //     e.target.setOptions({
            //         fillOpacity: 0.7
            //       })
            // },
            // mouseout: (e) => {
            //     e.target.setOptions({
            //         fillOpacity: 0.5
            //       })
            // }
        }
    }

    componentDidUpdate(prevProps) {
        if((JSON.stringify(prevProps.GEOJSON) !== JSON.stringify(this.props.GEOJSON))) {
            this.setState({
                GEOJSON: this.props.GEOJSON
            })
        }
        if((JSON.stringify(prevProps.markers) !== JSON.stringify(this.props.markers))) {
            this.setState({
                markers: this.props.markers.map(item => {
                    return {
                        ...item,
                        position: {
                            latitude: item.endLatitude,
                            longitude: item.endLongitude,
                        },
                        offset: {x: -28, y: -60}
                    }
                })
            }, () => {
                if(this.state.markers.length > 0) {
                    this.setState({
                        center: this.state.markers[0].position
                    })
                }
            })
        }
    }

    // 运行轨迹
    gotoTrack = (id) => {
        console.log(id)
        // 选择当前行
        this.setState({
            selectedRowKey: this.state.infoPos.id,
			selectedRow: this.state.infoPos,
        }, () => {
            Utils.showModal(this, "mapVisible", 1)
        })
    }

    /**
     * 地图放大全屏
     */
    mapFullScreen = () => {
        this.setState({
            fullscreen: !this.state.fullscreen
        })
    }

    render() {
        const { markers, center, GEOJSON, fullscreen } = this.state;
        // 1-10,11-100,101-1000…
        let markerClustererOptions = {
            styles: [{
                url: require("../../img/clusterer1.png"),
                size: [54, 54],
                textColor:'#fff',
                textSize: 16,
                offset: { x: -27, y: -27 }
            }, {
                url: require("../../img/clusterer2.png"),
                size: [54, 54],
                textColor:'#fff',
                textSize: 16,
                offset: { x: -27, y: -27 }
            }, {
                url: require("../../img/clusterer3.png"),
                size: [54, 54],
                textColor:'#fff',
                textSize: 16,
                offset: { x: -27, y: -27 }
            }, {
                url: require("../../img/clusterer4.png"),
                size: [54, 54],
                textColor:'#fff',
                textSize: 16,
                offset: { x: -27, y: -27 }
            }]
        }

        const areaColor = ['#8f82bc', '#f19ec2', '#89c997', '#fff799', '#84ccc9', '#f6b37f', '#2e6fa1', '#8c97cb', '#f29c9f', '#7ecef4'];

        return (
            <div className={fullscreen ? 'bc-map-box bc-map-fullscreen' : 'bc-map-box'} style={{width: "100%", height: "100%"}}>
                <Map amapkey={window.constants.mapKey} zoom={13} center={center} mapStyle='blue_night'>
                    <Markers markers={markers} useCluster={markerClustererOptions} events={this.markersEvents} render={(extData) => {
                        return (
                            <div className='marker-custem'>
                                <p>{extData.realName.length > 4 ? extData.realName.substr(extData.realName.length - 4) : extData.realName}</p>
                                <img src={require("../../img/marker-empty.png")} className="bc-mapInfo-img" alt="" />
                            </div>
                        )
                    }} />
                    <InfoWindow 
                        position={this.state.infoPos.position}
                        visible={this.state.infoVisible}
                        isCustom={true}
                        closeWhenClickMap={true}
                        offset={[0, -56]}
                        events={this.infoWindowEvents}
                    >
                        <div className="mapInfoWin-box">
                            <p>姓名：{this.state.infoPos.realName}</p>
                            <p>单位：{this.state.infoPos.groupNames}</p>
                            <p>时间：{this.state.infoPos.updatetime}</p>
                            <p>地址：{this.state.infoPos.endAddress}</p>
                            <p><span onClick={() => this.gotoTrack(this.state.infoPos.id)}>查看轨迹</span></p>
                        </div>
                    </InfoWindow>

                    {
                        GEOJSON.map((item, index) => {
                            return <Polygon key={index} path={JSON.parse(item.geojson)} events={this.PolygonEvents} style={polygonStyle(areaColor[index%10], '#2b8cbe')} />
                        })
                    }
                    {/* <FitView></FitView> */}
                </Map>
                {/* 查看运行轨迹 */}
                {this.state.mapVisible ? <MapTrack visible={this.state.mapVisible} callbackParent={params => Utils.hideModal(this, params)} initForm={this.state.selectedRow}></MapTrack> : null}
                {/* 放大/缩小 */}
                {
                    fullscreen ? <Icon type="fullscreen-exit" style={{
                        position: "absolute",
                        color: "#fff",
                        right: 20,
                        bottom: 20,
                        fontSize: 26
                    }} onClick={this.mapFullScreen} /> :
                    <Icon type="fullscreen" style={{
                        position: "absolute",
                        color: "#fff",
                        right: 10,
                        bottom: 10,
                        fontSize: 18
                    }} onClick={this.mapFullScreen} />
                }
                
                
                
            </div>
        )
    }
}

const polygonStyle = (fillColor, strokeColor) => {
    return {
        fillColor: fillColor,
        strokeOpacity: 1,
        fillOpacity: 0.5,
        strokeColor: strokeColor,
        strokeWeight: 1,
        strokeStyle: 'dashed',
        strokeDasharray: [5, 5],
    }
}

export default injectIntl(MapIndex)