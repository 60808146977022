import React from 'react'
import { Form } from '@ant-design/compatible';
import { Modal, Input, Avatar, Button, Menu, Tag, Icon } from 'antd';
import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react'
import screenfull from "screenfull";
import autofit from '@/utils/autofit.js'
import moment from "moment"
import API from "@/api/api"
import Utils from '@/utils/utils'
import Login from "./login"
// import EchartsDoubleaxes from "./component/echartsDoubleaxes"
import Echarts3dBar from "./component/echarts3dBar"
import ScrollBoard from './component/swiperScrollBoard';
import Map from './component/map'
import LineChartBar from './component/lineChartBar'
import Sunburst from './component/echartsSunburst';
// import EchartsColumn from "./component/echarsColumn"
import '../style.css'
// import GPS from "@/utils/map_translate.js"
const { Search } = Input;
const { SubMenu } = Menu;

let timeInterval = null;
let dataInterval = null;
const FormItem = Form.Item;
const { CheckableTag } = Tag;

@Form.create() @inject('appStore') @observer
class Index extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			nowTime: moment().format('YYYY年M月D日 dddd HH:mm:ss'),
			realName: "",
			userId: "",  // 管理员id
			loginVisible: false,
			visible: false,

			totalUserNumber: 0, // 总人数
			onlineNumber: 0, // 上班人数
			patrolNumber: 0, // 巡逻人数
			activeNumber: 0, // 激活人数
			doubleAxesData: [],  // 各街道网格员在线情况
			positionData: [], // 部门列表
			activeUserData: [], // 各部门激活人数
			onlineData: [], // 各街道在线人数
			trackLogList: [], // 轨迹记录

			markers: [],
			messageRecord: [], // 消息通知记录

			appUsage: [], // 应用使用情况排行
			statTop: [], // 网格员行走里程排行
			historyOnline: [], // 过去12天在线人数
			dutyNum: {},
			realName: '',
			streetArea: [],
			statTopSelected: 1
		}
	}

	async componentDidMount() {
		autofit.init({
			dw: 1920,
			dh: 1080,
			el: "#bigScreen",
			resize: true
		}, false)

		if(!(localStorage.getItem("time") && localStorage.getItem("time") > 1)) {
			localStorage.setItem("time", 1);
		}
		
		/*屏蔽浏览器默认右键事件*/
		document.oncontextmenu = function (e) {
			e = e || window.event;
			return false;
		};

		timeInterval = setInterval(() => {
            this.setState({
                nowTime: moment().format('YYYY年M月D日 dddd HH:mm:ss')
            })
        }, 1000)

		try {
			await this.getAdminInfo();
			this.ajaxData();
			this.setTime();
		} catch (error) {
			this.setState({
                loginVisible: true
            })
		}
	}

	componentWillUnmount() {
		clearInterval(timeInterval);
		clearInterval(dataInterval);
	}

	/**
     * 获取登陆者身份
     */
    getAdminInfo = () => {
        return new Promise((resolve, reject) => {
            let adminInfo = sessionStorage.getItem("adminInfo")
            let realName = localStorage.getItem("realName")
			let userId = localStorage.getItem("userId")
            if(adminInfo) {
                this.setState({
                    realName: JSON.parse(adminInfo).realName,
					userId: JSON.parse(adminInfo).id
                }, () => {
                    resolve()
                })
            } else if(userId) {
                this.setState({
                    realName: realName,
					userId: userId
                }, () => {
                    resolve()
                })
            } else {
                reject()
            }
        })
    }

	// 全屏
	screenfullToggle = () => {
		if(screenfull.isEnabled) {
			screenfull.toggle();
		}
	}

	ajaxData = async () => {
		this.getUserNum();
		this.getDeptNumber();
		this.getTrackLogList();
		
		// this.getMessageRecord();
		// this.getAppUsage();
		this.getStatTop();
		this.getHistoryOnWork();
		// await this.getStreetArea();
		this.getPositions();
	}

	// 设置定时刷新
	setTime = () => {
		clearInterval(dataInterval);
		let time = localStorage.getItem("time");
		if(time && time >= 1) {
			dataInterval = setInterval(() => {
				this.ajaxData();
			}, time * 60 * 1000)
		}
	}

	// 人员统计
	getUserNum = async () => {
		let res = await API.screenShow.countTotal({
			userId: this.state.userId
		});
		if(res.code === 200) {
			this.setState({
				totalUserNumber: res.content.totalUserNumber,
				activeNumber: res.content.activeNumber,
				onlineNumber: res.content.onlineNumber,
				patrolNumber: res.content.patrolNumber
			})
			this.getDutyNum();
		}
    }

	/**
	 * 获取各部门在线人数和激活人数
	 */
	getDeptNumber = async () => {
		let res = await API.screenShow.deptNumber({
			userId: this.state.userId
		});
		if(res.code === 200) {
			let positionData = [];
			let activeUserData = [];
			let onlineData = [];
			res.content.forEach(item => {
				positionData.push(item.groupName);
				activeUserData.push(item.activeNumber || 0);
				onlineData.push(item.onLineNumber)

			})
			this.setState({
				positionData,
				activeUserData,
				onlineData
			})
		}
	}


	// 执勤分布
	getDutyNum = async () => {
		let { onlineNumber, totalUserNumber, patrolNumber} = this.state;
		let data = [{
			name: "上班人数",
			value: onlineNumber,
			itemStyle: {
				color: "#3576f8",
			},
			children: [{
				name: "巡逻人数",
				value: patrolNumber,
				itemStyle: {
					color: "#9ceded",
				},
			}, {
				name: "其他人数",
				value: onlineNumber - patrolNumber,
				itemStyle: {
					color: "#96d9fc",
				},
			}],
		}, {
			name: "",
			itemStyle: {
				color: "#3cdb9d",
			},
			children: [{
				name: "休息人数",
				value: totalUserNumber - onlineNumber,
				itemStyle: {
					color: "#7ce294",
				},
			}],
		}]
		this.setState({
			dutyNum: data
		})
    }


	/**
	 * 获取网格员轨迹日志
	 */
	getTrackLogList = async () => {
		let res = await API.screenShow.patrolCountDay({
			userId: this.state.userId,
			pageNo: 0,
			pageSize: 100
		});
		if(res.code === 200) {
			let data = [];
			res.content.forEach((item, index) => {
				data.push([item.realName, item.groupName, item.createTime, Utils.formatDistance(item.patrolDistance), Utils.formatTimeAccount2(item.useTime), {type: 'button', id: item.id}]);
			})
			this.setState({
				trackLogList: data
			})
		}
	}

	/**
	 * 获取网格员实时定位
	 */
	getPositions = async () => {
		let res =await API.screenShow.mapRecordList({
			userId: this.state.userId
		});
		if(res.code === 200) {
			this.setState({
				markers: res.content
			})
		}
	}

	/**
	 * 查询消息通知记录
	 */
	getMessageRecord = async () => {
		let res = await API.grid.messageRecord({
			userId: this.state.userId
		});
		if(res.code === 200) {
			this.setState({
				messageRecord: res.content
			})
		}
	}

	/**
	 * 应用使用情况排行
	 */
	getAppUsage = async () => {
		let res = await API.grid.appUsage({
			userId: this.state.userId
		});
		if(res.code === 200) {
			this.setState({
				appUsage: res.content
			})
		}
	}

	/**
	 * 巡逻排行
	 */
	getStatTop = async () => {
		let res = await API.screenShow.patrolLeaderBoard({
			userId: this.state.userId,
			patrolType: this.state.statTopSelected
		});
		if(res.code === 200) {
			this.setState({
				statTop: res.content
			})
		}
	}
	
	// 一周内考勤分析
	getHistoryOnWork = async () => {
		let res = await API.screenShow.historyOnWork({
			userId: this.state.userId
		});
		if(res.code === 200) {
			this.setState({
				historyOnline: res.content.length > 0 ? res.content : []
			})
		}
	}

	/**
	 * 查询街道坐标数据
	 */
	getStreetArea = async () => {
		let res = await API.grid.area({
			userId: this.state.userId
		});
		if(res.code === 200) {
			this.setState({
				streetArea: res.content.length > 0 ? res.content.filter(item => item.level === 1 && item.geojson !== null) : []
			})

			// res.content.forEach(item => {
				
			// 	if(item.geojson){
			// 		let newdata = [];
			// 		JSON.parse(item.geojson).forEach(trans => {
			// 			if(typeof trans[0] === "object"){
			// 				let newdata2 = [];
			// 				trans.forEach(li => {
			// 					let geo = GPS.gcj_encrypt(li[1], li[0]);
			// 					newdata2.push([geo.lon, geo.lat]) 
			// 				})
			// 				newdata.push(newdata2);
			// 			}
			// 		})
			// 		console.log(item.name, JSON.stringify(newdata))
			// 	}
				
			// })
		}
	}

	// 打开配置窗口
	showModal = () => {
		this.setState({
			visible: true
		});
	};

	hideModal = (params) => {
        this.setState({
            userId: params.userId,
			realName: params.realName,
            loginVisible: false
        }, () => {
			this.ajaxData();
			this.setTime();

            // 是否记住账号
            if(params.remember) {
                localStorage.setItem("userId", params.userId)
				localStorage.setItem("realName", params.realName)
            }
        })
    }
	
	handleOk = () => {
		this.props.form.validateFields((err, values) => {
            if(!err) {
				localStorage.setItem("title", values.title || '');

				this.props.form.resetFields();
				this.setState({
					visible: false
				});
            }
        });
	};
	
	handleCancel = () => {
		this.props.form.resetFields();
		this.setState({ visible: false });
	};

	gotoExit = async (e) => {
        e.preventDefault();
        // await API.logout();
        localStorage.removeItem("realName");
		localStorage.removeItem("userId");
        sessionStorage.removeItem("adminInfo");
        sessionStorage.removeItem("token");
        this.props.appStore.toggleLogin(false);
        clearInterval(dataInterval)
        this.setState({
            userId: "",
			realName: "",
            loginVisible: true
        })
    }

	gotoSearch = val => {
		this.setState({
			realName: val
		}, () => {
			this.getTrackLogList();
		})
	}

	statTopChange = val => {
		this.setState({
			statTopSelected: val
		},() => {
			this.getStatTop()
		})
	}


	render() {
		const { activeNumber, onlineNumber, patrolNumber, totalUserNumber, trackLogList, messageRecord, historyOnline } = this.state;
		const { intl: { formatMessage } } = this.props;
		const { getFieldDecorator } = this.props.form;

		const menu = <Menu>
				<SubMenu title="南山区">
					<Menu.Item>南头街道</Menu.Item>
					<Menu.Item>沙河街道</Menu.Item>
				</SubMenu>
			</Menu>

		return(
			<div id="bigScreen" className="bc-wrap">
				<div className="bc-body">
					<div className="bc-header">
						<div className="bc-header-left">
							<span className="bc-icon clock"></span>
							<span className="bc-header-time">{this.state.nowTime}</span>
						</div>
						<div className="bc-header-title"><span>{localStorage.getItem("title") || '保物通数据大屏'}</span></div>
						<div className="bc-header-right">
							{/* <Dropdown overlay={menu}>
								<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
								南山区 <Icon type="down" />
								</a>
							</Dropdown> */}
							
							<span className="bc-icon admin"></span>
							<span className="bc-login-name">{this.state.realName || ''}</span>
							{ this.state.userId ? <a className="bc-login-btn" href="javascript:void(0);" onClick={this.gotoExit}>{formatMessage({id: 'ExitBtn'})}</a> : null }
							{ screenfull.isFullscreen ? <span className="bc-icon shrink" onClick={this.screenfullToggle}></span> : <span className="bc-icon arrowsAlt" onClick={this.screenfullToggle}></span> }
							<span className="bc-icon setting" style={{marginLeft: '15px', cursor: 'pointer'}} onClick={this.showModal}></span>
						</div>
					</div>
					<div className="bc-main">
						<div className="bc-left">
							<div className="bc-box">
								<div className="bc-box-title">总体情况统计</div>
								<div className="bc-box-in bc-count-box">
									<div className="bc-count-smbox">
										<div className="bc-count-top one">
											<span className="bc-icon big person"></span>
											<span>总人数</span>
										</div>
										<div className="bc-count-num">{totalUserNumber}</div>
									</div>
									<div className="bc-count-smbox">
										<div className="bc-count-top two">
											<span className="bc-icon big active"></span>
											<span>激活人数</span>
										</div>
										<div className="bc-count-num">{activeNumber}</div>
									</div>
									<div className="bc-count-smbox">
										<div className="bc-count-top three">
											<span className="bc-icon big clock"></span>
											<span>上班人数</span>
										</div>
										<div className="bc-count-num">{onlineNumber}</div>
									</div>
									<div className="bc-count-smbox">
										<div className="bc-count-top four">
											<span className="bc-icon big patrol"></span>
											<span>巡查人数</span>
										</div>
										<div className="bc-count-num">{patrolNumber}</div>
									</div>
								</div>
							</div>
							{/* <div className="bc-box" style={{marginBottom: '33px'}}>
								<div className="bc-box-title">网格员在线情况</div>
								<div className="bc-box-in" style={{height: '268px'}}>
									<EchartsDoubleaxes data={this.state.doubleAxesData}></EchartsDoubleaxes>
								</div>
							</div> */}
							<div className="bc-box" style={{marginBottom: '33px'}}>
								<div className="bc-box-title">各部门人员统计</div>
								<div className="bc-box-in" style={{height: '268px'}}>
									<Echarts3dBar positionData={this.state.positionData} activeUserData={this.state.activeUserData} onlineData={this.state.onlineData}></Echarts3dBar> 
								</div>
							</div>
							<div className="bc-box">
								<div className="bc-box-title">历史考勤分析</div>
								<div className="bc-box-in" style={{height: '309px'}}>
									<LineChartBar data={historyOnline}></LineChartBar>
								</div>
							</div>
							
						</div>
						<div className="bc-center">
							<div className="bc-box" style={{marginBottom: '17px'}}>
								<div className="bc-box-title">实时在线地图</div>
								<div className="bc-box-in" style={{height: '920px', padding: 0}}>
									<Map markers={this.state.markers} GEOJSON={this.state.streetArea} onlyMap={this.state.onlyMap}></Map>
								</div>
							</div>
							{/* <div className="bc-box">
								<div className="bc-box-title">消息通知记录</div>
								<div className="bc-box-in" style={{height: '156px'}}>
									
								</div>
							</div> */}
						</div>
						<div className="bc-right">
							<div className="bc-box">
								<div className="bc-box-title">巡逻排行榜</div>
								<div className="bc-box-in" style={{height: '368px'}}>
									<div className='bc-person-checkTags'>
										<CheckableTag checked={this.state.statTopSelected === 1} onChange={() => this.statTopChange(1)}>昨天</CheckableTag>
										<CheckableTag checked={this.state.statTopSelected === 7} onChange={() => this.statTopChange(7)}>最近7天</CheckableTag>
										<CheckableTag checked={this.state.statTopSelected === 30} onChange={() => this.statTopChange(30)}>最近30天</CheckableTag>
									</div>
									<div className="bc-person-box">
									{
										this.state.statTop.map((item, index) => {
											return <div key={index} className={"bc-person-item num" + (index + 1)} style={{order: index === 0 ? 2 : index === 1 ? 1 : 3}}>
												<img src={require("../img/num" + (index + 1) + ".png")} alt="" />
												<div className="bc-person-img"><Avatar size={index === 0 ? 108 : 64} src={item.avatar ? item.avatar : require("../img/" + (item.sex === 1 ? 'male' : 'female') +".png")} alt={item.realName} /></div>
												<div className="bc-person-name">{item.realName}</div>
												<div>{Utils.formatDistance(item.patrolDistance)}</div>
											</div>
										})
									}
									</div>
								</div>
							</div>
							{/* <div className="bc-box" style={{marginBottom: '33px'}}>
								<div className="bc-box-title">执勤分布</div>
								<div className="bc-box-in">
									<Sunburst data={this.state.dutyNum} sumNum={this.state.totalUserNumber}></Sunburst>
								</div>
							</div> */}
							<div className="bc-box">
								<div className="bc-box-title">巡逻统计</div>
								<div className="bc-box-in bc-scrollBoard" style={{height: '504px'}}>
									<Search placeholder="输入姓名" enterButton onSearch={val => this.gotoSearch(val) } />
									<ScrollBoard data={trackLogList} header={['姓名', '部门', '日期', '巡逻距离', '巡逻时长', '操作']} columnWidth={[90,90,90,70,70,70,70]} rowNum={12} autoplay={true} isTrack={true} />
								</div>
							</div>
						</div>
					</div>
				</div>
				
				{
					this.state.visible ? <Modal
						visible={this.state.visible}
						title=""
						onOk={this.handleOk}
						onCancel={this.handleCancel}
						width={'440px'}
						centered
						maskClosable={false}
						footer={null}
					>
						<div className="bc-modal">
							<div className="bc-modal-header">修改配置</div>
							<Form layout="vertical" ref="myForm">
								<FormItem className="bc-modal-item" label={<span className="bc-modal-label">标题</span>}>
								{getFieldDecorator('title', {
									initialValue: localStorage.getItem("title") || '保物通数据大屏'
								})(
									<Input placeholder="20个字符以内" className="bc-modal-input" maxLength={20} />
								)}
								</FormItem>

								<FormItem className="bc-modal-item">
									<div className="bc-modal-btnbox">
										<Button key="submit" size="large" type="primary" htmlType="submit" shape="round" onClick={this.handleOk} className="bc-modal-btn">确定</Button>
										<Button key="back" size="large" shape="round" onClick={this.handleCancel} className="bc-modal-btn">取消</Button>
									</div>
								</FormItem>
							</Form>
						</div>
					</Modal> : null
				}
				<Login visible={this.state.loginVisible} callbackParent={this.hideModal}></Login>
			</div>
		)
	}
}

export default injectIntl(Index)