/* eslint-disable no-useless-escape */
// 多个地方会使用到的全局变量
window.constants = {
    baseURL: process.env.NODE_ENV === "development" ? "/api" : "",
    isMergePlatform: true,   // 是否与管控平台合并
    mapKey: "27052259488cdc131d9d5da4e84e4344",   // 高德地图key
    mobilePhoneReg: /^1[3-9]\d{9}$/,   // 手机号码
    emailReg: /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/,   // 电子邮箱
    idCardReg: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,   // 身份证
    // 账号，请输入8-16位字符，至少包含数字、大写字母、小写字母、特殊字符中的三种类型
    accountReg: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,16}$/,
    formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
    },
    MAP_MARGIN: 50,
    version: '通用版V1.4.2',
    menus: [{
    //     menuName: "首页",
    //     iconName: "home",
    //     requestUrl: "/page/home/index",
    //     isMenu: 1,
    //     id: 1
    // }, {
        menuName: "通讯录",
        confName: "通讯录管理",
        iconName: "contacts",
        requestUrl: "/page/home/contacts/user",
        isMenu: 1,
        id: 2
    }, {
        menuName: "工作台",
        confName: "工作台管理",
        iconName: "appstore",
        requestUrl: "/page/home/workbench",
        isMenu: 1,
        id: 3
    }, {
        menuName: "日志",
        confName: "操作日志查看",
        iconName: "profile",
        requestUrl: "/page/home/logs",
        isMenu: 1,
        id: 4
    }, {
        menuName: "设置",
        iconName: "setting",
        requestUrl: "/page/home/setting",
        isMenu: 1,
        id: 5,
    }, {
        menuName: "数据大屏",
        iconName: "setting",
        requestUrl: "/page/bigScreen",
        isMenu: 1,
        id: 7,
        blank: true
    }],
    settingMenus: [{
        menuName: "企业设置",
        confName: "企业设置",
        requestUrl: "/page/home/setting/enterpriseSettings",
        id: 1
    }, {
        menuName: "默认密码设置",
        confName: "默认密码管理",
        requestUrl: "/page/home/setting/defaultPwdSettings",
        id: 2
    }, {
        menuName: "修改登录密码",
        confName: "登录密码管理",
        requestUrl: "/page/home/setting/changeLoginPwd",
        id: 3
    }, {
        menuName: "设置子管理员",
        confName: "设置子管理员",
        requestUrl: "/page/home/setting/setSubAdmin",
        id: 4
    }, {
        menuName: "授权许可",
        confName: "授权许可管理",
        requestUrl: "/page/home/setting/authorization",
        id: 5
    }, {
        menuName: "版本更新",
        confName: "版本管理",
        requestUrl: "/page/home/setting/appVersionUpdate",
        id: 6
    }, {
        menuName: "数据字典",
        confName: "数据字典",
        requestUrl: "/page/home/setting/dataDictionary",
        id: 7
    }, {
        menuName: "帮助中心",
        confName: "帮助中心",
        requestUrl: "/page/home/setting/help",
        id: 8
    }, {
        menuName: "用户反馈",
        confName: "用户反馈",
        requestUrl: "/page/home/setting/feedback",
        id: 10
    }, {
        menuName: "敏感词配置",
        confName: "敏感词配置",
        requestUrl: "/page/home/setting/sensitiveWord",
        id: 12
    }, {
        menuName: "系统配置",
        confName: "系统配置",
        requestUrl: "/page/home/setting/system",
        id: 11
    }, {
        menuName: "关于我们",
        confName: "关于我们",
        requestUrl: "/page/home/setting/about",
        id: 9
    }],
    officeManage: [{
        name: "考勤管理",
        requestUrl: "/page/home/attendance",
        icon: 'attendance.png',
        id: 3
    }, {
        name: "巡逻管理",
        requestUrl: "/page/home/patrol",
        icon: 'patrol.png',
        id: 1
    }, {
        name: "签到管理",
        requestUrl: "/page/home/signIn",
        icon: 'signIn.png',
        id: 2
    }],
    DICTIONARY_TYPE: [  // 数据字典类型
        { fieldName: "OAFormType", typeCode: "FLOWTYPE" }, // OA表单分组
    ],
    mergedMenus: [3, 5],  // 合并平台的菜单
    mergedSettingMenus: [6, 7, 8, 9, 10]  // 合并平台的设置侧边菜单
}
