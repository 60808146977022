const en_US = {
    Hello: 'Hello', //你好
    Add: 'Add',  // 添加
    Update: 'Update', // 修改
    Delete: 'Delete', //删除
    Yes: 'Yes', //是
    No: 'No', //否
    OK: 'OK', // 确定
    Cancel: 'Cancel', //取消
    Search: 'Search', // 搜索
    All: 'All', // 全部
    Save: 'Save', // 保存
    See: 'See', //查看
    Reset: 'Reset', // 重置
    Prompt: 'Prompt', //提示
    Other: 'Other', //其他
    HomePage: 'Home page', //首页
    NoResult: 'No result', //暂无数据
    UserCenter: 'User center', //用户中心
    AccountInfo: 'Account Info', //账号资料
    Logout: 'Logout', //退出登录
    ChangeBasicInformation: 'Change basic information', // 修改基本信息
    Account: 'Account', //账号
    Mailbox: 'Email', //邮箱
    PhoneNumber: 'Phone number', // 电话号码
    AdministratorsName: "Administrator's name", // 管理员姓名
    InputItem: 'Please input the {name}.', // 请输入{name}
    PleaseSelect: 'Please select {name}.', // 请选择{name}
    PleaseConfirmPassword: 'Please confirm password', //请确认密码
    TwoPasswordDiff: 'Two passwords that you enter is inconsistent!', //两次输入的密码不一致
    InputCorrect: 'Please enter the correct {name}', //请输入正确的{name}

    EnterpriseSettings: 'Enterprise settings', //企业设置
    ChangeLoginPwd: 'Change login password', //修改登录密码
    DefaultPwdSettings: "Default password settings", //默认密码设置
    Licensing: 'Licensing', //授权许可
    EnterpriseNameAndLogoSetting: 'Enterprise name and logo setting', //企业名称与LOGO设置
    EnterpriseFullName: 'Full name of enterprise', //企业名称全称
    EnterpriseAbbreviationName: 'Enterprise name abbreviation', //企业名称简称
    CorporateLogo: 'Corporate logo', //企业LOGO
    UploadImgInPNG: 'Please upload pictures in PNG format and within 300KB', //请上传PNG格式，而且大小在300KB以内的图片
    Upload: 'Upload', //上传
    ChangePwd: 'Change Password', //修改密码
    CurPwd: 'Current password', //当前密码
    NewPwd: 'New password', //新密码
    ConfirmPwd: 'Confirm password', //确认密码
    OriginalPwd: 'Original password', //原密码
    PwdRule: '8-16 characters, including at least three types of numbers, uppercase letters, lowercase letters and special characters', // 8-16位字符，至少包含数字、大写字母、小写字母、特殊字符中的三种类型
    CurMasterAdmin: 'Current master administrator', //当前主管理员
    NewMasterAdmin: 'New master administrator', //新的主管理员
    Member: 'Member', //成员
    WillBeNormalAdmin: 'After replacement, you will become a normal administrator!', //更换后，您将变为普通管理员权限！
    Confirm: 'Confirm', //确定
    ManageScope: 'Management scope', //管理范围
    Jurisdiction: 'Jurisdiction', //权限
    Operation: 'Operation', //操作
    UploadLicenseFile: 'Upload license file', //上传license文件
    LicenseInfo: 'License information', //License信息

    SuccessfulOperation: 'Successful operation', // 操作成功
    CustomerName: "Customer name", //客户名称
    LicenseType: "License type", //License类型
    OfficialUse: "Official use", //正式使用
    OnTrial: "Trial use", // 试用
    AvailableDate: "Available date", //可使用日期
    StartDate: "Start date", //开始日期
    NumberLicenses: "Number of Licenses", //License数量
    NumberUsedLicenses: "Number of used Licenses", //已使用License数量
    NumberRemainingLicenses: "Number of remaining licenses", //剩余License数量
    LicenseIncorrect: "The file format is incorrect and the name of the file must be license.lic.", // 文件格式不正确，文件名称必须为license.lic
    SetSubAdmin: "Set sub administrator",
    Read: 'Read', // 已读
    UnRead: 'Unread', // 未读
    PlatformMessage: 'Platform message', // 平台消息
    MessageContents: "Message contents", // 消息内容
    CreationTime: "Creation time", // 创建时间
    State: 'State', //状态
    UserImportExcessive: "The number of imported users is excessive, currently only {num} users can be imported, please upgrade the license certificate!", // 导入用户数量超额，当前只能导入 {num} 个用户，请升级license证书！
    Workbench: "Workbench", //工作台
    TotalRecords: "A total of {totalNum} records, and {selectedNum} record has been selected.", // 共{totalNum}条记录，已选择{selectedNum}条记录
    UserManagement: "User", // 用户管理
    DepartmentManagement: "Departmental", // 部门管理
    DepartmentName: "Name", // 部门名称
    DepartmentCode: "Code", // 部门代码
    ContactCerson: "Contact", // 联系人
    ContactNumber: "Contact number", // 联系电话
    TypeOfPlace: "Type of place", // 场所类型
    LocationAddress: "Address", // 场所地址
    QRCode: "QR code", // 二维码
    BatchPrintQRcode: "Batch print QR code", // 批量打印二维码
    BatchImport: "Batch import", // 批量导入
    Print: "Print", // 打印
    SelectAddress: "Select address", // 选择地址
    longitude: "longitude", // 经度
    latitude: "latitude", // 纬度
    DeptCodeExists: "Department code {name} already exists", //部门代码 {name} 已存在
    RememberLoginStatus: "Remember login status", // 记住登录状态
    Authenticate: "Authenticate", // 身份认证
    Login: 'Login', //登录
    ExitBtn: "Exit", //退出
}
export default en_US;