export default(state,action)=>{
    switch (action.payload){
        case 'en':
            return Object.assign({},state,{language:action.type})
        case 'zh':
            return Object.assign({},state,{language:action.type})
        case 'fresh':
            return Object.assign({}, state, {fresh: action.type})
        case 'platInfo':
            return Object.assign({},state,{platInfo: action.type })
        case 'userGroup':
            return Object.assign({},state,{userGroup: action.type })
        case 'OAFormType':
            return Object.assign({},state,{OAFormType: action.type })
        default:
        return state
    }
}