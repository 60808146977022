import React, {Component} from 'react';
import PrivateRoute from './components/PrivateRoute'
import {Route,Switch} from 'react-router-dom'
import Login from './routes/Login/index'
import Index from './routes/Index/index'
import License from "./routes/License/index"
// 大屏可视化
import BigScreen from "./routes/BigScreen/Index"

import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import API from "@/api/api"
import redux from "@/redux/Store"
import Action from '@/redux/Action'
import Utils from '@/utils/utils';

import './App.css'

@withRouter @inject('appStore')
class App extends Component {
	state = {
		isJudged: false,   // 判断是否完毕
	}

	async componentDidMount() {
		if(window.constants.isMergePlatform) {
			let token = Utils.getUrlParams('token', window.location.search);
			if(token !== null && token !== undefined && token !== '') {
				// 获取并保存token
				sessionStorage.setItem("token", token);
				// 获取用户基本信息
				let res = await API.admin.findAdmin();
				let adminInfo = res.content.a;
				adminInfo.groupName = res.content.g.groupName;
				sessionStorage.setItem("adminInfo", JSON.stringify(adminInfo))
				sessionStorage.removeItem("userInfo");
				this.props.appStore.toggleLogin(true, {loginName: JSON.parse(sessionStorage.getItem("adminInfo")) ? JSON.parse(sessionStorage.getItem("adminInfo")).loginName : ''})

				// 获取用户单位数据
				redux.dispatch(await Action.getUserGroup("update"));
				const {from} = {from: {pathname: '/'}};
				this.props.history.push(from);
				redux.dispatch(await Action.getPlatInfo("update"));
				this.setState({
					isJudged: true
				})
			} else {
				this.setState({
					isJudged: true
				})
			}
		} else {
			this.setState({
				isJudged: true
			})
		}
	}

	render() {
		return this.state.isJudged ? <Switch>
			<Route path='/page/login' component={Login}/>
			<License path='/page/license' component={License}/>
			<Route path='/page/bigScreen' exact component={BigScreen}/>
			<PrivateRoute path='/' component={Index}/>
		</Switch> : null
	}
}

export default App;