import API from '@/api/api'
import redux from '../Store'

export default {
    async getUserGroup(type) {
        let data = redux.getState().userGroup
        if (type === 'update' || redux.getState().userGroup.length === 0) {
            let res = await API.group.groupList();
            data = res.content ? res.content : []
        }
        return {
            type: data,
            payload: 'userGroup'
        }
    },
    async getPlatInfo(type) {
        let platInfo = redux.getState().platInfo;
        if(type === 'update' || !redux.getState().platInfo){
            let res = !sessionStorage.getItem("adminInfo") && sessionStorage.getItem("userInfo") ? await API.general.findPlatFormInfoUser() : await API.admin.findPlatFormInfo();
            platInfo = res.content;
        }
        return {
            type: platInfo,
            payload: 'platInfo'
        }
    },
    /**
     * 根据类型获取数据字典的数据
     * @param {*} name 对应store中的参数名
     * @param {*} type 是否要更新，传'update'或者不传
     */
    async getDictionaryData(name, type){
        let data = redux.getState()[name];
        let dictType = window.constants.DICTIONARY_TYPE.find(item => item.fieldName === name)
        if(!dictType){
            console.log("未找到store中的参数对应的字典值");
            return;
        }
        if(type === 'update' || !data.length){
            let res = await API.dictConfig.getDictByTypeCode({
                typeCode: dictType.typeCode
            });
            data = res.content;
        }
        return {
            type: data,
            payload: name
        }
    }
}