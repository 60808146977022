import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Card, message, Upload } from 'antd';
import API from '@/api/api.js';
const FormItem = Form.Item;

@Form.create()
class Licensing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			licenseModel: {},  // license信息
            currUserAmount: 0, // 当前用户数量
        }
    }

    async componentDidMount() {
		// this.getRemoteData();
	}

	getRemoteData = async () => {
        let res = await API.lic.get();
        this.setState({
            licenseModel: res.content.licenseModel ? res.content.licenseModel : {},
            currUserAmount: res.content.currUserAmount
        })
	}

    render() {
		const formItemLayout = {
			labelCol: { span: 6 },
			wrapperCol: { span: 18 },
		};
		// 国际化调API
		const { intl: { formatMessage } } = this.props;
		const props = {
			customRequest: async info => {
				if(info.file.name !== "license.lic") {
					message.error(formatMessage({id:'LicenseIncorrect'}));
					return;
				}
				var formData = new FormData();
				formData.append('file', info.file);
				await API.lic.add(formData);
				message.success(formatMessage({id:'SuccessfulOperation'}));
				this.getRemoteData();
			},
			showUploadList: false
		};
        return (
            <div>
				<div className="setting-title"><FormattedMessage id="Licensing" /></div>
				<Upload {...props}>
					<Button type="primary" icon={<UploadOutlined />}><FormattedMessage id="UploadLicenseFile" /></Button>
				</Upload>
				{/* License授权信息 */}
				<Card style={{ marginTop: 35 }} type="inner" title={(<FormattedMessage id="LicenseInfo" />)}>
					{/* 客户名称 */}
					<FormItem label={(<FormattedMessage id="CustomerName" />)} {...formItemLayout}>
						{ this.state.licenseModel.customerName }
					</FormItem>
					{/* License类型   正式使用 / 试用 */}
					<FormItem label={(<FormattedMessage id="LicenseType" />)} {...formItemLayout}>
						{ this.state.licenseModel.licenseType ? (<FormattedMessage id="OfficialUse" />) : (<FormattedMessage id="OnTrial" />) }
					</FormItem>
					{/* 有效期至 */}
					<FormItem label={(<FormattedMessage id="AvailableDate" />)} {...formItemLayout}>
						{ this.state.licenseModel.notAfter }
					</FormItem>
					{/* License总数 */}
					<FormItem label={(<FormattedMessage id="NumberLicenses" />)} {...formItemLayout}>
						{ this.state.licenseModel.userAmount }
					</FormItem>
					{/* 已使用License数量 */}
					<FormItem label={(<FormattedMessage id="NumberUsedLicenses" />)} {...formItemLayout}>
						{ this.state.currUserAmount }
					</FormItem>
					{/* 剩余License数量 */}
					<FormItem label={(<FormattedMessage id="NumberRemainingLicenses" />)} {...formItemLayout}>
						{ this.state.licenseModel.userAmount && this.state.currUserAmount ? (this.state.licenseModel.userAmount - this.state.currUserAmount) : 0 }
					</FormItem>
                </Card>
			</div>
        );
    }
}

export default injectIntl(Licensing);