import React from 'react'
import { withRouter, Switch, Redirect } from 'react-router-dom'
import LoadableComponent from '@/utils/LoadableComponent'
import PrivateRoute from '../PrivateRoute'

// 首页
// const HomePage = LoadableComponent(() => import("@/routes/HomePage/index"))
// 未激活人员
const InactivePeople = LoadableComponent(() => import("@/routes/HomePage/inactivePeople"))

// 设置-企业设置
const EnterpriseSettings = LoadableComponent(() => import("@/routes/Setting/EnterpriseSettings/index"))
// 设置-默认密码设置
const DefaultPwdSettings = LoadableComponent(() => import("@/routes/Setting/DefaultPwdSettings/index"))
// 设置-修改登录密码
const ChangeLoginPwd = LoadableComponent(() => import("@/routes/Setting/ChangeLoginPwd/index"))
// 设置-设置子管理员
const SetSubAdmin = LoadableComponent(() => import("@/routes/Setting/SetSubAdmin/index"))
// 设置-授权许可
const Authorization = LoadableComponent(() => import("@/routes/Setting/Authorization/index"))
// 设置-APP版本更新
const AppVersionUpdate = LoadableComponent(() => import("@/routes/Setting/AppVersionUpdate/index"))
// 设置-关于我们
const About = LoadableComponent(() => import("@/routes/Setting/About/index"))
// 设置-数据字典
const DataDictionary = LoadableComponent(() => import("@/routes/Setting/DataDictionary/index"))
// 设置- 帮助中心
const Help = LoadableComponent(() => import("@/routes/Setting/Help/index"))
// 设置- 系统配置
const System = LoadableComponent(() => import("@/routes/Setting/System/index"))
// 设置- 用户反馈
const Feedback = LoadableComponent(() => import("@/routes/Setting/Feedback/index"))
// 设置- 用户反馈
const SensitiveWord = LoadableComponent(() => import("@/routes/Setting/SensitiveWord/index"))

// 通讯录 - 用户管理
const User = LoadableComponent(() => import("@/routes/Contacts/User/index"))
// 通讯录 - 部门管理
const Department = LoadableComponent(() => import("@/routes/Contacts/Department/index"))

// 日志
const Logs = LoadableComponent(() => import("@/routes/Logs/index"))

// 工作台
const Workbench = LoadableComponent(() => import("@/routes/Workbench/index"))

// 新建/编辑应用信息
const AppModal = LoadableComponent(() => import("@/routes/Workbench/modal"))

// 考勤管理
const Attendance = LoadableComponent(() => import("@/routes/Attendance"))

// 上岗打卡
const DutyClockIn = LoadableComponent(() => import("@/routes/DutyClockIn"))

// 巡逻报备
const PatrolReport = LoadableComponent(() => import("@/routes/Patrol"))

// 签到
const SignIn = LoadableComponent(() => import("@/routes/SignIn"))

// 打卡
const ClockIn = LoadableComponent(() => import("@/routes/ClockIn"))

@withRouter
class ContentMain extends React.Component {
	render () {
		let firstMenu = window.constants.isMergePlatform ? window.constants.menus.filter(item => window.constants.mergedMenus.some(e => e === item.id)) : window.constants.menus;
		let defaultUrl = firstMenu[0].requestUrl;
		
		return (
			<div style={{position: 'relative',padding: '16px 0'}}>
				<Switch>
					{/* <PrivateRoute exact path='/page/home/index' component={HomePage}/> */}
					<PrivateRoute exact path='/page/home/setting' component={window.constants.isMergePlatform ? AppVersionUpdate : EnterpriseSettings}/>
					<PrivateRoute exact path='/page/home/setting/enterpriseSettings' component={EnterpriseSettings}/>
					<PrivateRoute exact path='/page/home/setting/defaultPwdSettings' component={DefaultPwdSettings}/>
					<PrivateRoute exact path='/page/home/setting/changeLoginPwd' component={ChangeLoginPwd}/>
					<PrivateRoute exact path='/page/home/setting/setSubAdmin' component={SetSubAdmin}/>
					<PrivateRoute exact path='/page/home/setting/authorization' component={Authorization}/>
					<PrivateRoute exact path='/page/home/setting/appVersionUpdate' component={AppVersionUpdate}/>
					<PrivateRoute exact path='/page/home/contacts/user' component={User}/>
					<PrivateRoute exact path='/page/home/contacts/department' component={Department}/>
					<PrivateRoute exact path='/page/home/logs' component={Logs}/>
					<PrivateRoute exact path='/page/home/inactivePeople' component={InactivePeople}/>
					<PrivateRoute exact path='/page/home/workbench' component={Workbench}/>
					<PrivateRoute exact path='/page/home/workbench/modal' component={AppModal}/>
					<PrivateRoute exact path='/page/home/setting/about' component={About}/>
					<PrivateRoute exact path='/page/home/setting/dataDictionary' component={DataDictionary}/>
					<PrivateRoute exact path='/page/home/setting/help' component={Help}/>
					<PrivateRoute exact path='/page/home/setting/feedback' component={Feedback}/>
					<PrivateRoute path='/page/home/attendance' component={Attendance}/>
					<PrivateRoute path='/page/home/setting/system' component={System}/>
					<PrivateRoute path='/page/home/setting/sensitiveWord' component={SensitiveWord}/>
					<PrivateRoute path='/page/home/dutyClockIn' component={DutyClockIn}/>
					<PrivateRoute path='/page/home/patrol' component={PatrolReport}/>
					<PrivateRoute path='/page/home/clockIn' component={ClockIn}/>
					<PrivateRoute path='/page/home/signIn' component={SignIn}/>
					
					<Redirect exact from='/' to={defaultUrl} />
				</Switch>
			</div>
		)
	}
}

export default ContentMain