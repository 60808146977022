import React from 'react'
import { Route, Redirect, } from 'react-router-dom'
import { isAuthenticated } from '../../utils/Session'

const PrivateRoute = ({component: Component, ...rest}) => (
  <Route {...rest} render={(props) => (
    !!isAuthenticated()
      ? <Component {...props} />
      : window.constants.isMergePlatform ? (window.location = "/page/login") : <Redirect to={{
        pathname: '/page/login',
        state: {from: props.location}
      }}/>
  )}/>
)

export default PrivateRoute