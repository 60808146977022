import React from "react"
import { Map, Polyline, Marker } from 'react-amap';
import { Button, Modal, Spin } from 'antd';
import API from "@/api/api.js";
import TrackMap from '@/components/TrackComponents/map'

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            path: [],
            startPoi: {
                longitude: 116.404,
                latitude: 39.915
            },
            endPoi: {
                longitude: 116.404,
                latitude: 39.915
            },
            spinning: true,
            originPath: []
        }
    }

    componentDidMount() {
        this.setState({
            id: this.props.initForm.id
        }, () => {
            this.getLocationList();
        })
    }

    // 获取定位数据
    getLocationList = async () => {
        let res = await API.patrol.recordList({
            patrolId: this.state.id
        })
        let data = res.content;
        if(data && data.length) {
            // 设置地图中心
            // let path = data.map(item => {
            //     return {
            //         longitude: Number(item.longitude),
            //         latitude: Number(item.latitude)
            //     }
            // })
            this.setState({
                startPoi: {
                    latitude: data[0].latitude,
                    longitude: data[0].longitude,
                },
                endPoi: {
                    latitude: data[data.length-1].latitude,
                    longitude: data[data.length-1].longitude,
                },
                originPath: data,
                spinning: false
            })
        }
    }

    handleCancel = (e) => {
        //这里将子组件的信息传递给了父组件
        this.props.callbackParent({
            visibleType: "mapVisible",
        });
    }

    render() {
        return (
            <Modal
            title="巡逻轨迹"
            width={1200} destroyOnClose={true}
            visible={this.props.visible}
            confirmLoading={this.state.loading}
            bodyStyle={{padding: 0}}
            onCancel={this.handleCancel}
            footer={
                <Button key="close" onClick={() => this.handleCancel()}>关闭</Button>
            }
            >
                <Spin tip="加载中..." spinning={this.state.spinning}>
                    <div style={{height: '800px'}}>
                        <Map amapkey={window.constants.mapKey} zoom={12} center={this.state.startPoi} useAMapUI>
                            {/* <Polyline 
                                path={this.state.path} 
                                style={{
                                    strokeColor: '#58b6d3',
                                    strokeWeight: '4',
                                    lineJoin: 'round',
                                    lineCap: 'round'
                                }}
                            /> */}
                            <TrackMap originPath={this.state.originPath} />
                            {/* <Marker position={this.state.startPoi}>
                                <img src={require("@/assets/img/start.png")} width={30} alt="" style={mapPoi} />
                            </Marker>
                            <Marker position={this.state.endPoi}>
                                <img src={require("@/assets/img/end.png")} width={30} alt="" style={mapPoi} />
                            </Marker> */}
                        </Map>
                    </div>
                </Spin>
            </Modal>
        )
    }
}

const mapPoi = { marginTop: '-10px', marginLeft: '-6px' }

export default Index