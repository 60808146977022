const zh_CN = {
    Hello: '你好',
    Add: '添加',
    Update: '修改',
    Delete: '删除',
    Yes: '是',
    No: '否',
    OK: '确定',
    Cancel: '取消',
    Search: '搜索',
    All: '全部',
    Save: '保存',
    See: '查看',
    Reset: '重置',
    Prompt: '提示',
    Other: '其他',
    HomePage: '首页',
    NoResult: '暂无数据',
    UserCenter: '用户中心',
    AccountInfo: '账号资料',
    Logout: '退出登录',
    ChangeBasicInformation: '修改基本信息',
    Account: '账号',
    Mailbox: '邮箱',
    PhoneNumber: '电话号码',
    AdministratorsName: '管理员姓名',
    InputItem: '请输入{name}',
    PleaseSelect: '请选择{name}',
    PleaseConfirmPassword: '请确认密码',
    TwoPasswordDiff: '两次输入的密码不一致',
    InputCorrect: '请输入正确的{name}',

    EnterpriseSettings: '企业设置',
    ChangeLoginPwd: '修改登录密码',
    DefaultPwdSettings: "默认密码设置",
    Licensing: '授权许可',
    EnterpriseNameAndLogoSetting: '企业名称与LOGO设置',
    EnterpriseFullName: '企业名称全称',
    EnterpriseAbbreviationName: '企业名称简称',
    CorporateLogo: '企业LOGO',
    UploadImgInPNG: '请上传PNG格式，而且大小在300KB以内的图片',
    Upload: '上传',
    Password: "密码",
    ChangePwd: '修改密码',
    CurPwd: '当前密码',
    NewPwd: '新密码',
    ConfirmPwd: '确认密码',
    OriginalPwd: '原密码',
    PwdRule: '8-16位字符，至少包含数字、大写字母、小写字母、特殊字符中的三种类型',
    CurMasterAdmin: '当前主管理员',
    NewMasterAdmin: '新的主管理员',
    Member: '成员',
    WillBeNormalAdmin: '更换后，您将变为普通管理员权限！',
    Confirm: '确定',
    ManageScope: '管理范围',
    Jurisdiction: '权限',
    Operation: '操作',
    UploadLicenseFile: '上传license文件',
    LicenseInfo: 'License信息',

    SuccessfulOperation: '操作成功',
    CustomerName: "客户名称",
    LicenseType: "License类型",
    OfficialUse: "正式使用",
    OnTrial: "试用",
    AvailableDate: "有效期至",
    StartDate: "开始日期",
    NumberLicenses: "License总数",
    NumberUsedLicenses: "已使用License数量",
    NumberRemainingLicenses: "剩余License数量",
    LicenseIncorrect: "文件格式不正确，文件名称必须为license.lic",


    EmployeeType: "员工类型",
    EmployeeStatus: "员工状态",
    Position: "职位",
    Gender: "性别",
    Male: "男",
    Female: "女",
    IDCardNum: "身份证号",
    Department: "部门",
    PhoneNum: "手机号码",
    JobNum: "工号",
    FullName: "姓名",
    Exists: "已存在相同{name}",
    CannotTopGroup: "不能选择顶级单位",
    FullTime: "全职",
    PartTime: "兼职",
    Internship: "实习",
    laborDispatch: "劳务派遣",
    RetirementReemployment: "退休返聘",
    LaborOutsourcing: "劳务外包",
    Formal: "正式",
    PhoneNumTips: "已激活的人员，无法修改手机号码。",
    FullNameTips: "已激活的人员，无法修改姓名。",
    Edit: "编辑",
    UpperLimit: 'license数量已满，请升级license',
    SearchPlaceHolder: "搜索姓名、职位、工号、手机号码",
    PleaseCheck: "请选中表格中的某一条记录",
    SureDelete: "删除后数据将不可恢复，您确定要执行此操作？",
    BulkDelete: "批量删除",
    BulkImport: "批量导入",
    Export: "导出",
    SameExists: "存在相同的{name}",
    DownloadUserTemplate: "下载人员模板",
    UserImportCount: "已导入 {num1} 个人员，还可导入 {num2} 个人员。",
    UploadTempFormat: "请使用模板格式上传",
    LineFormatError: "第 {n} 行格式错误",
    UploadFormat: "请按照模板格式上传",
    lineEmpty: "第 {n} 行 {name} 不能为空",
    lineIncorrect: "第 {n} 行 {name} 格式不正确",
    lineRepeat: "第 {n} 行和第 {m} 行 {name} 重复了",
    Separated: "第 {n} 行 {name} 的部门上下级须用/隔开",
    JobNumExists: "工号 {name} 已存在",
    PhoneNumExists: "手机号码 {name} 已存在",
    IDCardNumExists: "身份证号 {name} 已存在",
    ImportUser: "请导入人员信息",
    Description: '描述',
    SuperiorDepartment: "上级部门",
    // DepartmentName: "部门名称",
    DepartmentValue: "{name}部门",
    PersonnelValue: "{name}人员",
    DefaultPwd: "默认密码",
    SettingDefaultPwd: "设置员工登录APP的默认密码",
    DefaultPwdRule: "密码不低于6位不长于15位",
    Log: "日志",
    AdminOperationRecord: "管理员操作记录",

    Time: "时间",
    Administrator: "管理员",
    OperationObject: "操作对象",
    OperationType: "操作类型",
    DetailedData: "详细数据",
    ManageBackground: "管理后台",
    Contact: "通讯录",
    NumOfEnterprises: "企业人数",
    NumOfDepartments: "部门数",
    NumOfUsersYesterday: "昨日使用人数",
    Geshu: "个",
    Person: "人",
    PeopleNumnotActivated: "未激活{num}人",
    InactivePeople: "未激活人员",
    NoInactivePeople: "无未激活人员",
    Role: "角色",
    AddRole: "添加角色",
    RoleValue: "{name}角色",
    RoleName: "角色名称",
    AddMember: "添加成员",
    BulkRemoval: "批量移除",
    SelectPeople: "选择人员",
    BulkRemovalTips: "确定将选中的数据从该角色中移除？",
    AppVersionUpdate: "APP版本更新",
    AppVersion: "APP版本",
    UploadTime: "上传时间",
    UploadNewVersion: "上传新版本{name}",
    VersionNo: "版本号",
    PleaseUpload: "请上传应用安装包",
    BasicApplication: "微应用",
    Information: "资讯",
    AllTypes: "全部类型",
    NewCategory: "新建分类",
    Publish: "发布",
    SearchTitleContent: "搜索标题",
    RecallAndDelete: "撤回并删除",
    ReadNum: "{num}人已读",
    Title: "标题",
    Category: "分类",
    ContentForm: "内容形式",
    ImageAndText: "图文",
    Video: "视频",
    CoverPhoto: "封面图片",
    Text: "正文",
    ClickUpload: "点击上传",
    Enclosure: "附件",
    UploadVideo: "上传视频",
    PleaseUploadVideo: "请上传视频",
    ImageUploadType: "仅允许上传jpg、jpeg、png、bmp、gif格式图片",
    ImageUploadSize: "图片大小必须在5M以内",
    FileUploadType: "仅支持.doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx,.zip类型文件",
    FileUploadSize: "文件大小必须在30M以内",
    VideoUploadType: "仅支持mp4格式视频",
    VideoUploadSize: "视频大小必须在500M以内",
    CategoryName: "分类名称",
    SetSubAdmin: "设置子管理员",
    Status: "状态",
    AssignPermissions: "分配权限",
    Read: '已读', 
    UnRead: '未读', 
    PlatformMessage: '平台消息', 
    MessageContents: "消息内容",
    CreationTime: "创建时间", 
    State: '状态', 
    UserImportExcessive: "导入用户数量超额，当前只能导入 {num} 个用户，请升级license证书！",
    Workbench: "工作台",
    TotalRecords: "共 {totalNum} 条记录，已选择 {selectedNum} 条记录",
    DateExists: "日期 {name} 已存在",
    ImportHoliday: "请导入法定节假日",
    UserManagement: "用户管理",
    DepartmentManagement: "部门管理",
    DepartmentName: "部门名称",
    DepartmentCode: "部门代码",
    ContactCerson: "联系人",
    ContactNumber: "联系电话",
    TypeOfPlace: "场所类型",
    LocationAddress: "场所地址",
    QRCode: "二维码",
    BatchPrintQRcode: "批量打印二维码",
    BatchImport: "批量导入", 
    Print: "打印",
    SelectAddress: "选择地址", 
    longitude: "经度",
    latitude: "纬度", 
    DeptCodeExists: "部门代码 {name} 已存在",
    DayAccount: "{days}天{hours}小时{minutes}分钟",
    HoursAccount: "{hours}小时{minutes}分钟",
    MinutesAccount: "{minutes}分钟",
    Detail: "详情",
    RememberLoginStatus: "记住登录状态",
    Authenticate: "身份认证",
    Login: '登录',
    ExitBtn: "退出",
}
export default zh_CN;