import React from "react"
import { message } from 'antd'
import { FormattedMessage } from 'react-intl';
import redux from '../redux/Store'
import Action from '../redux/Action'
import {PUBLIC_KEY} from "./rsa_key"
import GPS from "@/utils/map_translate.js"
import moment from 'moment';
import API from "../api/api"

function accMul(arg1, arg2) {
    let m = 0;
    const s1 = arg1.toString();
    const s2 = arg2.toString();
    m += s1.split(".").length > 1 ? s1.split(".")[1].length : 0;
    m += s2.split(".").length > 1 ? s2.split(".")[1].length : 0;
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / 10 ** m;
}

/**
 * 清空输入框数据
 */
function emitEmpty(name,_this) {
    _this[name].focus();
    _this.props.form.setFieldsValue({[name]: ''})
}

export default {
	digitUppercase(n) {
		const fraction = ['角', '分'];
		const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
		const unit = [['元', '万', '亿'], ['', '拾', '佰', '仟', '万']];
		let num = Math.abs(n);
		let s = '';
		fraction.forEach((item, index) => {
			s += (digit[Math.floor(accMul(num, 10 * 10 ** index)) % 10] + item).replace(/零./, '');
		});
		s = s || '整';
		num = Math.floor(num);
		for (let i = 0; i < unit[0].length && num > 0; i += 1) {
			let p = '';
			for (let j = 0; j < unit[1].length && num > 0; j += 1) {
				p = digit[num % 10] + unit[1][j] + p;
				num = Math.floor(num / 10);
			}
			s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
		}

		return s
			.replace(/(零.)*零元/, '元')
			.replace(/(零.)+/g, '零')
			.replace(/^整$/, '零元整');
	},

	/**
	 * 生成指定区间的随机整数
	 * @param min
	 * @param max
	 * @returns {number}
	 */
	randomNum(min, max) {
		return Math.floor(Math.random() * (max - min) + min);
	},

	/**
	 * 计算提示框的宽度
	 * @param str
	 * @returns {number}
	 */
	calculateWidth(arr) {
		return 30 + arr[0].length * 15
	},

	/**
	 * 图片预加载
	 * @param arr
	 * @constructor
	 */
	preloadingImages(arr) {
		arr.forEach(item => {
			const img = new Image()
			img.src = item
		})
	},

	/**
	 * @visibleType:String  显示弹框的类型
	 * @mustSelected:Int 是否必须选择一条数据或多条数据
	 */
	showModal(_this, visibleType, mustSelected) {
		if(mustSelected) {
			if(mustSelected === 1 && _this.state.selectedRowKey) {
				_this.setState({
					[visibleType]: true
				})
			} else if(mustSelected === 2 && _this.state.selectedRowKeys.length) {
				_this.setState({
					[visibleType]: true
				})
			} else {
				// 请选择一行
				const { intl: { formatMessage } } = _this.props
				const PleaseCheck = formatMessage({id:'PleaseCheck'});
				message.destroy();
				message.warning(PleaseCheck);
			}
		} else {
			_this.setState({
				[visibleType]: true
			})
		}
	},

	/**
	 * Modal 弹窗组件回调方法，关闭弹窗
	 */
	hideModal(_this, params) {
		_this.setState({
			[params.visibleType]: false,
			hoverIndex: -1,
		})

		// 刷新数据
		if(params.dataRefresh) {
			// 延迟刷新
			setTimeout(() => {
				if(params.visibleType === "deleteVisible") {
					_this.setState({
						selectedRowKey: "",
						selectedRowKeys: [],
						pageNo: 0
					}, () => {
						_this.getRemoteData()
					})
				} else {
					_this.getRemoteData();
				}
			}, 1000);
		}
	},

	/**
	 * 过滤数据中的空childs
	 */
	filterEmptyChilds(data) {
		if(data) {
			// 遍历数组
			data.forEach(item => {
				// 遍历对象
				for(let key in item) {
					if(key === 'childs') {
						if(item[key] && !item[key].length) {
							delete item[key];
						} else {
							this.filterEmptyChilds(item[key]);
						}
					}
				}
			})
		}
		return data;
	},

  	// 根据ID匹配返回树形结构数据的对象
	filterSelectedRow(id, data) {
		for(let i = 0; i < data.length; i++) {
			if(data[i].id === id) {
				return data[i];
			} else {
				if(data[i].childs && data[i].childs.length) {
					let selectRow = this.filterSelectedRow(id, data[i].childs);
					if(selectRow) {
						return selectRow;
					}
				}
			}
		}
	},

	/**
	 * 表格 分页、排序、筛选变化时触发
	 */
	onTableChange(_this, pagination, filters, sorter, extra) {
		// 当排序发生变化时
		if(sorter && Object.keys(sorter).length > 0){
			_this.setState({
				sortField: sorter.field,
				sortOrder: sorter.order ? sorter.order.replace("end","") : sorter.order
			}, () => {
				_this.getRemoteData()
			})
		}
	},

	/**
	 * 分页改变
	 */
	onPageChange(_this, page, pageSize) {
		_this.setState({
			pageNo: page - 1,
			pageSize: pageSize
		}, () => {
			// 重新加载数据
			_this.getRemoteData();
		})
	},

	/**
	 * 统计时间 XX天XX小时XX分钟
	 * @param {*} time
	 */
	formatTimeAccount(time) {
		var days    = Math.floor(time / 1000 / 60 / 60 / 24);
		var hours   = Math.floor(time/ 1000 / 60 / 60 - (24 * days));
		var minutes = Math.floor(time / 1000 /60 - (24 * 60 * days) - (60 * hours));
		if(days === 0) {
			if(hours === 0) {
				return (<FormattedMessage id="MinutesAccount" values={{minutes}} />)
			} else {
				return (<FormattedMessage id="HoursAccount" values={{hours, minutes}} />)
			}
		} else {
			return (<FormattedMessage id="DayAccount" values={{days, hours, minutes}} />)
		}
	},

	/**
	 * 统计时间 XX天XX小时XX分钟
	 * @param {*} time
	 */
	formatTimeAccount2(time) {
		var days    = Math.floor(time / 1000 / 60 / 60 / 24);
		var hours   = Math.floor(time/ 1000 / 60 / 60 - (24 * days));
		var minutes = Math.floor(time / 1000 /60 - (24 * 60 * days) - (60 * hours));
		if(days === 0) {
			if(hours === 0) {
				return minutes + '分钟'
			} else {
				return hours + '小时' + minutes + '分钟'
			}
		} else {
			return days + '天' + hours + '小时' + minutes + '分钟'
		}
	},

	formatDateAccount(time) {
		var days    = Math.floor(time / 1000 / 60 / 60 / 24);
		var hours   = Math.floor(time/ 1000 / 60 / 60);
		var minutes = Math.floor(time / 1000 /60);
		if(days === 0) {
			if(hours === 0) {
				return (<FormattedMessage id="MinutesAgo" values={{n: minutes}} />)
			} else {
				return (<FormattedMessage id="HoursAgo" values={{n: hours}} />)
			}
		} else {
			return (<FormattedMessage id="DaysAgo" values={{n: days}} />)
		}
	},

	formatAppSize(appSize) {
		if(appSize < 1024) {
			return appSize + " B"
		} else if(appSize < 1024*1024) {
			return (appSize/1024).toFixed(2) + " KB"
		} else if(appSize < 1024*1024*1024) {
			return (appSize/1024/1024).toFixed(2) + " MB"
		} else if(appSize < 1024*1024*1024*1024) {
			return (appSize/1024/1024/1024).toFixed(2) + " GB"
		}
	},

	/**
	 * 增加一行应用输入框
	 */
	addAppInput(_this, key) {
		let appList = _this.state[key]
		appList.push({
			appName: '',
			appPackage: ''
		})
		_this.setState({ [key]: appList })
	},

	/**
	 * 删除一行应用输入框
	 */
	removeAppInput(_this, index, key) {
		const { form } = _this.props;
		let appList = form.getFieldValue(key);
		appList.splice(index, 1);
		if (appList.length === 0) {
			appList.push({
				appName: '',
				appPackage: ''
			})
		}
		form.setFieldsValue({ [key]: appList })
		_this.setState({ [key]: appList })
	},

	/**
	 * 获取工作单位全称
	 */
	getGroupCompleteName(group) {
		if(group.pGroup) {
			return this.getGroupCompleteName(group.pGroup) + "-" + group.groupName
		} else {
			return group.groupName
		}
	},

	/*
	* 控制输入框清除按钮是否显示
	*/
	suffix(_this,name,Icon) {
		const visible = _this.props.form.getFieldValue(name) ? {visibility: 'visible'} : {visibility: 'hidden'}
		return <Icon type="close-circle" onClick={() => emitEmpty(name,_this)} style={{ ...visible, color: 'rgba(0,0,0,.25)'}}/>
	},

	// 过滤父级用户组选项数据，添加disabled禁用项
	filterCascaderOptions(data, id) {
		data.forEach(item => {
			if(item.id === id) {
				item.disabled = true;
			} else {
				item.disabled = false;
				if(item.childs && item.childs.length){
					item.childs = this.filterCascaderOptions(item.childs, id);
				}
			}
		})
		return data;
	},

	formatGroupName(group){
		let name = group.groupName
		if(group.pGroup){
			// name += "/" + this.formatGroupName(group.pGroup)
			if(group.pGroup.groupName !== "组织结构"){
				name = this.formatGroupName(group.pGroup) + "/" + name
			}
		}
		return name;
	},

	async getGroupTree(_this, type) {
		redux.dispatch(await Action.getUserGroup(type))
		if (!Object.is(JSON.stringify(redux.getState().userGroup) ,JSON.stringify(_this.state.groupTree))) {
			_this.setState({
				groupTree: redux.getState().userGroup
			})
		}
	},

	/**
	 * 判断所选单位id，是否是顶级单位
	 * @param {*} id
	 */
	checkTopGroup(id) {
		let groupTree = redux.getState().userGroup;
		// console.log(groupTree, id)
		return groupTree.length && groupTree[0].id === id && !groupTree[0].pId
	},

	getCurNavState(type, id) {
		let adminInfo = JSON.parse(sessionStorage.getItem("adminInfo"));
		if(!adminInfo.createBy) {
			return true
		}
		if(type === 'system') {
			let systemMenu = JSON.parse(adminInfo.menus).system;
			return systemMenu.includes(id);
		} else if(type === 'setting') {
			let settingMenu = JSON.parse(adminInfo.menus).setting;
			return settingMenu.includes(id);
		} else if(type === 'lightApp') {
			let lightApp = JSON.parse(adminInfo.lightApp);
			return lightApp.includes(id);
		}
	},

	// AES加密
	aesEncrypt(str, key){
		var key1 = window.CryptoJS.enc.Utf8.parse(key);
		var str1 = window.CryptoJS.enc.Utf8.parse(str);
		var encryptedData = window.CryptoJS.AES.encrypt(str1, key1, {
			mode: window.CryptoJS.mode.ECB,
			padding: window.CryptoJS.pad.Pkcs7
		});
		var encryptedStr = encryptedData.toString();
		return encryptedStr;
	},
	// AES解密
	aesDecrypt(str, key){
		// 数据解密
		var key1 = window.CryptoJS.enc.Utf8.parse(key);
		//加密服务端返回的数据
		var decryptedData = window.CryptoJS.AES.decrypt(str, key1, {
			mode: window.CryptoJS.mode.ECB,
			padding: window.CryptoJS.pad.Pkcs7
		});
	
		var decryptedStr = window.CryptoJS.enc.Utf8.stringify(decryptedData).toString();
		return decryptedStr;
	},
	// RSA加密
	rsaEncrypt(str){
		//使用公钥加密
		var encrypt = new window.JSEncrypt();
		encrypt.setPublicKey('-----BEGIN PUBLIC KEY-----' + PUBLIC_KEY + '-----END PUBLIC KEY-----');
		var encrypted = encrypt.encrypt(str);
		return encrypted;
	},
	// 随机生成密钥
	randomKey(n){
		var chars = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
		if(n==null){
			n = 16;
		}
		var res = "";
		for(var i = 0; i < n ; i ++) {
			var id = Math.ceil(Math.random()*35);
			res += chars[id];
		}
		return res;
	},
	//   是否是超级管理员
	isAdmin() {
		let adminInfo = JSON.parse(sessionStorage.getItem("adminInfo"));
		let groupIds = JSON.parse(adminInfo.groupIds);
		return groupIds.length <= 1;
	},
	//  超级管理员ID
	adminId(){
		let adminInfo = JSON.parse(sessionStorage.getItem("adminInfo"));
		let groupIds = JSON.parse(adminInfo.groupIds);
		return groupIds[0];
	},
	// 当前登录管理员自己的ID
	selfId(){
		let adminInfo = JSON.parse(sessionStorage.getItem("adminInfo"));
		return adminInfo.id;
	},
	// 时间转成对应的时长，分钟转小时
	timeToDuration(num) {
		if(num === 0) {
			return 0
		}
		let number = Math.abs(num);
		let hours = Math.floor(parseFloat(number/60));
		let minutes = parseInt(number%60);
		return (num < 0 ? '-' : '') + (hours > 0 ? (hours + '小时') : '') + (minutes > 0 ? (minutes + '分钟') : '');
	},
	timeStamp(time) {
		const dates = new Date(time)
		const year = dates.getFullYear()
		const month = dates.getMonth() + 1
		const date = dates.getDate()
		const day = dates.getDay()
		const hour = dates.getHours()
		const min = dates.getMinutes()
		const days = ['日', '一', '二', '三', '四', '五', '六']
		return {
		  allDate: `${year}-${this.strFormat(month)}-${this.strFormat(date)}`,
		  date: `${this.strFormat(month)}-${this.strFormat(date)}`, //返回的日期 07-01
		  day: `星期${days[day]}`, //返回的礼拜天数  星期一
		  hour: this.strFormat(hour) + ':' + this.strFormat(min) //返回的时钟 08:00
		}
	},
	strFormat(str) {
		return str < 10 ? `0${str}` : str
	},
	formatDistance(distance){
		return distance > 1000 ? Math.round((distance || 0)/100) / 10 + '千米' : Math.round((distance || 0)) + '米'
	},
	// 过滤原始数据点，将同一个位置的点合并到一起
	filterPoints(originPath){
		if(originPath.length){
			let stopPoint = originPath[0];
			let points = [originPath[0]];
			// 计算两点之间的距离
			let distance = 0;
			for(let i = 0; i < originPath.length; i++){
			// GPS坐标，转成高德坐标
				// let res = GPS.gcj_encrypt(originPath[i].latitude*1, originPath[i].longitude*1);
				originPath[i].x = originPath[i].longitude;
				originPath[i].y = originPath[i].latitude;
				// 开始时间
				if(i === originPath.length - 1){
				originPath[i].startTime = moment(stopPoint.createtime).format('HH:mm');
				}
				stopPoint.startTime = moment(stopPoint.createtime).format('HH:mm');
				// stopPoint.endtime = stopPoint.createtime;
				// 结束时间（不带日期）
				// stopPoint.endTime = moment(stopPoint.createtime).format('HH:mm');
				
				
				if(i > 0){
					// 获取两点之间的距离
					let p1 = [stopPoint.x, stopPoint.y];
					let p2 = [originPath[i].x, originPath[i].y];
					let dis = window.AMap.GeometryUtil.distance(p1, p2);
					distance += dis;
					
					// 小于50米的点合并
					if(dis > window.constants.MAP_MARGIN ){
						// 计算两点之间的距离（多个未停留的点距离需累计）
						originPath[i].distance = distance;
						points.push(originPath[i]);
	
						stopPoint = originPath[i];
						
						
					}else{
						stopPoint.endtime = originPath[i].createtime;
						// 结束时间（不带日期）
						stopPoint.endTime = moment(originPath[i].createtime).format('HH:mm');
						
						
						
	
						// 计算停留的时间（如果当前点的开始时间和上一个点的结束时间重叠，则没有停留）
						if(stopPoint.endTime !== originPath[i].startTime){
						let stopTime = moment(originPath[i].createtime).valueOf() - moment(stopPoint.createtime).valueOf();
						stopPoint.stopTime = stopTime;
						stopPoint.stopTimeText = this.formatTimeAccount2(stopTime);
						
						distance = 0;
						}
					}
				}
				
			}
			return points;
		}
		return []
	},
	/**
 * 根据经纬度获取地址
 */
	async getAddress(AMap, lon, lat) {
		return new Promise((resolve, reject) => {
			
			AMap.plugin('AMap.Geocoder', function() {
				var geocoder = new AMap.Geocoder({
				  city: '全国' // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
				})
			  
				// 使用geocoder做地理/逆地理编码
				geocoder.getAddress([lon, lat], function(status, result) {
				  // console.log(result)
					if (status === 'complete' && result.info === 'OK') {
						// result为对应的地理位置详细信息
						let address = result.regeocode ? result.regeocode.formattedAddress : ""
						resolve(address)
					}else{
						resolve("")
					}
				})
			})
		})
	},
	getUrlParams(name, str) {
		const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
		const r = str.substr(1).match(reg);
		if (r != null) return decodeURIComponent(r[2]);
		return null;
	},

	async downloadFile(param) {
		// 后台返回文件流
		let res = await API.file.download({
			url: param.url
		});
		let blob = new Blob([res]);
		let url = window.URL.createObjectURL(blob);
		let downloadElement = document.createElement('a');
		downloadElement.style.display = 'none';
		downloadElement.href = url;
		downloadElement.download = param.name ? param.name : param.url.substring(param.url.lastIndexOf('/')+1);
		document.body.appendChild(downloadElement);
		downloadElement.click();
		document.body.removeChild(downloadElement);
		window.URL.revokeObjectURL(url);
	},
	async previewImage(param) {
		// 后台返回文件流
		let res = await API.file.preview({
			url: param.url
		});
		let blob = new Blob([res]);
		let url = window.URL.createObjectURL(blob);
		return url
	}
}
