import React from 'react'
import { Layout, ConfigProvider } from 'antd'
import ContentMain from '@/components/ContentMain'
import HeaderBar from '@/components/HeaderBar'
import redux from '@/redux/Store'
import Action from '@/redux/Action'

const { Header, Content } = Layout

class Index extends React.Component{
	state = {
		collapsed: false
	}

	// 顶栏
	get topbar() {
		let platInfo = redux.getState().platInfo;
		let themeContent = platInfo && platInfo.themeContent && platInfo.themeContent !== '' ? JSON.parse(platInfo.themeContent) : null;
		return window.constants.isMergePlatform ? (themeContent && themeContent.topbar && themeContent.topbar !== '' ? themeContent.topbar : 'dark') : 'light'
	}

	async componentDidMount() {
		redux.dispatch(await Action.getPlatInfo('update'));
		let platInfo = redux.getState().platInfo;
		let themeContent = platInfo.themeContent && platInfo.themeContent !== '' ? JSON.parse(platInfo.themeContent) : null;
		ConfigProvider.config({
			theme: {
				primaryColor: themeContent && themeContent.primaryColor && themeContent.primaryColor !== '' ? themeContent.primaryColor : '#1890ff'
			}
		});
	}

	toggle = () => {
		this.setState({
			collapsed: !this.state.collapsed
		})
	}

	render() {
		// 设置Sider的minHeight可以使左右自适应对齐
		return (
			<div id='page'>
				<Layout>
					<Header className={'common-header ' + this.topbar}>
						<HeaderBar collapsed={this.state.collapsed} onToggle={this.toggle} hasMenu={true}/>
						
						{/* <Button type="primary" ghost style={{ position: "absolute", right: 20, top: 16}}>开放平台</Button> */}
					</Header>
					<Content className="content-main">
						<ContentMain/>
					</Content>
				</Layout>
			</div>
		);
	}
}
export default Index