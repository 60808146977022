import React from 'react'
import { Link,withRouter } from 'react-router-dom'
import { Menu } from 'antd'
import redux from '@/redux/Store'

//此组件的意义就是将数据抽离出来，通过传递数据去渲染
@withRouter
class CustomMenu extends React.Component {
	state = {
		openKeys: [],
		selectedKeys: [],
		menus: window.constants.isMergePlatform ? window.constants.menus.filter(item => window.constants.mergedMenus.some(e => e === item.id)) : window.constants.menus
	}

	get topbar() {
        let platInfo = redux.getState().platInfo;
        let themeContent = platInfo && platInfo.themeContent && platInfo.themeContent !== '' ? JSON.parse(platInfo.themeContent) : null;
        return window.constants.isMergePlatform ? (themeContent && themeContent.topbar && themeContent.topbar !== '' ? themeContent.topbar : 'dark') : 'light'
    }

	componentDidMount() {
		// 防止页面刷新侧边栏又初始化了
		const pathname = this.props.location.pathname
		//获取当前所在的目录层级
		const rank = pathname.split('/')
		switch (rank.length) {
		case 2 :  //一级目录
			this.setState({
				selectedKeys: [pathname]
			})
			break;
		case 5 : //三级目录，要展开两个subMenu
			this.setState({
				selectedKeys: [pathname],
				openKeys: [rank.slice(0, 3).join('/'), rank.slice(0, 4).join('/')]
			})
			if(rank[3] === 'setting') {
				this.setState({
					selectedKeys: [pathname.substring(0, pathname.lastIndexOf('/'))],
				})
			}
			break;
		default :
			this.setState({
				selectedKeys: [pathname],
				openKeys: [pathname.substr(0, pathname.lastIndexOf('/'))]
			})
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//当点击面包屑导航时，侧边栏要同步响应
		const pathname = nextProps.location.pathname
		if (this.props.location.pathname !== pathname) {
			this.setState({
				selectedKeys: [pathname],
			})
		}
	}

	onOpenChange = (openKeys) => {
		//此函数的作用只展开当前父级菜单（父级菜单下可能还有子菜单）
		if (openKeys.length === 0 || openKeys.length === 1) {
			this.setState({
				openKeys
			})
			return
		}

		//最新展开的菜单
		const latestOpenKey = openKeys[openKeys.length - 1]
		//判断最新展开的菜单是不是父级菜单，若是父级菜单就只展开一个，不是父级菜单就展开父级菜单和当前子菜单
		//因为我的子菜单的key包含了父级菜单，所以不用像官网的例子单独定义父级菜单数组，然后比较当前菜单在不在父级菜单数组里面。
		//只适用于3级菜单
		if (latestOpenKey.includes(openKeys[0])) {
			this.setState({
				openKeys
			})
		} else {
			this.setState({
				openKeys: [latestOpenKey]
			})
		}
	}
	renderMenuItem = ({requestUrl, iconName, menuName, isMenu, id}) => {
		if(isMenu === 1 && requestUrl){
			return (
				<Menu.Item key={requestUrl}>
					<Link target={ id === 7 ? '_blank' : '_self' } to={{ pathname : requestUrl , query : { timestamp : new Date().getTime() }}}>
						{/* {iconName && <Icon type={iconName}/>} */}
						<span>{menuName}</span>
					</Link>
				</Menu.Item>
			)
		}
	}

	renderSubMenu = ({requestUrl, iconName, menuName, childs}) => {
		return (
			<Menu.SubMenu key={requestUrl} title={menuName}>
				{
				childs && childs.map(item => {
					return item.childs && item.childs.length > 0 && item.isMenu === 0 ? this.renderSubMenu(item) : this.renderMenuItem(item)
				})
				}
			</Menu.SubMenu>
		)
	}

	render() {
		const {openKeys, selectedKeys} = this.state
		return (
			<Menu
				onOpenChange={this.onOpenChange}
				openKeys={openKeys}
				selectedKeys={selectedKeys}
				mode='horizontal'
				theme={this.topbar === 'primary' ? 'dark' : this.topbar}
				className="header-menu">
				{
				this.state.menus && this.state.menus.map(item => {
					return item.childs && item.childs.length > 0 && item.isMenu === 0 ? this.renderSubMenu(item) : this.renderMenuItem(item)
				})
				}
			</Menu>
		)
	}
}

export default CustomMenu