import 'antd/dist/antd.variable.min.css';
import '@/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import "babel-polyfill";
import "./utils/config.js"
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import {BrowserRouter} from 'react-router-dom'
import { Provider} from 'mobx-react'
import { ConfigProvider } from 'antd'
import store1 from './store'
import redux from './redux/Store'

//react-intl 国际化
import {IntlProvider,addLocaleData} from 'react-intl';
import zh_CN from './locales/zh_CN';//个人配置
import en_US from './locales/en_US';//个人配置
// import intl from 'intl';
import zh from 'react-intl/locale-data/zh';//react-intl语言包
import en from 'react-intl/locale-data/en';//react-intl语言包
import antd_CN from "antd/lib/locale-provider/zh_CN"
import antd_US from "antd/lib/locale-provider/en_US"
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
require("es6-symbol/implement");
addLocaleData([...en, ...zh]);//需要放入本地数据库

// 获取语言类型
let locale = redux.getState().language

//打包时，用的HashRouter并加上了basename，因为放在服务器的二级目录下
const render = () => ReactDOM.render(
	<IntlProvider locale={locale} messages={locale === 'zh' ? zh_CN : en_US} >
		<BrowserRouter>
			<ConfigProvider locale={locale === 'zh' ? antd_CN : antd_US}>
			<Provider {...store1}>
				<App/>
			</Provider>
			</ConfigProvider>
		</BrowserRouter>
	</IntlProvider>, document.getElementById('root')
);
render()
registerServiceWorker();
redux.subscribe(() => {
	locale = redux.getState().language
	render()
})
