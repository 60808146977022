import React from "react"
import { Form } from '@ant-design/compatible';
import {Modal, Input, Checkbox, Icon, Button, message} from "antd"
import { FormattedMessage, injectIntl } from 'react-intl';
import API from "@/api/api"
import md5 from "js-md5"

const FormItem = Form.Item;
@Form.create()
class Index extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if(!err){
                values.password = md5(values.password)
                this.setState({
                    loading: true
                })
                
                let res = await API.screenShow.checkAccount(values)

                if(res.code === 200){
                    // 国际化调API
                    // const { intl: { formatMessage } } = this.props
                    // const successTips = formatMessage({id:'VerificationSuccess'});
                    // message.success(successTips);
                    //这里将子组件的信息传递给了父组件
                    this.props.callbackParent({
                        realName: res.content.realName,
                        userId: res.content.id,
                        remember: values.remember
                    });
                }
            }
        });
    }

    /**
     * 点击取消按钮
     */
    // handleCancel = (e) => {
    //     //这里将子组件的信息传递给了父组件
    //     this.props.callbackParent({
    //         visibleType: "loginVisible"
    //     });
    //     this.setState({ loading: false })
    // }

    render() {
        const { getFieldDecorator} = this.props.form;
        const { intl: { formatMessage } } = this.props
        return (
            <Modal wrapClassName="bigScreenLogin" width={350} destroyOnClose={true} centered={true} closable={false} footer={null} visible={this.props.visible}>
                <Form layout="horizontal" ref="myForm" onSubmit={this.handleSubmit}>
                    <div style={{fontSize: '20px',textAlign: 'center',paddingBottom: '35px'}}><FormattedMessage id='Authenticate' /></div>
                    {/* 账号 */}
                    <FormItem>
                        {getFieldDecorator('loginName')(
                            <Input maxLength={32} size='large' suffix={<Icon type="user" style={{ color: '#eee',fontSize: '18px' }} />} placeholder={ formatMessage({id:'Account'}) } />
                        )}
                    </FormItem>
                    {/* 密码 */}
                    <FormItem>
                        {getFieldDecorator('password')(
                            <Input maxLength={32} type="password" size='large' suffix={<Icon type="lock" style={{ color: '#eee',fontSize: '18px' }} />} placeholder={ formatMessage({id:'Password'}) } />
                        )}
                    </FormItem>
                    <FormItem style={{marginBottom: '0'}}>
                        <Button block size='large' htmlType="submit"><FormattedMessage id="Login" /></Button>
                        <div style={{ textAlign: 'right'}}>
                            {getFieldDecorator('remember', {
                                valuePropName: 'checked',
                                initialValue: true,
                            })(<Checkbox><FormattedMessage id="RememberLoginStatus" /></Checkbox>)}
                        </div>
                    </FormItem>
                </Form>
            </Modal>
        )
    }
}

export default injectIntl(Index)