import React from 'react'
import { injectIntl } from 'react-intl';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css';
import 'swiper/modules/autoplay/autoplay.min.css';
import Utils from '@/utils/utils'
// 查看运行轨迹
import MapTrack from "../../../Patrol/map.js"

let swiper = null;
class SwiperScrollBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            header: [],
            rowNum: 6,
            columnWidth: [],
            isTrack: false,
            mapVisible: false,
            selectedRowKey: '',
            selectedRow: null
        }
    }

    componentDidMount() {
        this.setState({
            header: this.props.header,
            rowNum: this.props.rowNum,
            isTrack: this.props.isTrack,
            columnWidth: this.props.columnWidth && this.props.columnWidth.length > 0 ? this.props.columnWidth : []
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if((JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) || (nextState.mapVisible !== this.state.mapVisible)) {
            return true
        }
        return false
    }

    componentDidUpdate() {
        
    }
    
    getRem = (px) => {
        return (px / 192) + 'rem'
    }

    getStyle = (index) => {
        const { columnWidth } = this.state;
        return {
            width: (columnWidth.length > 0 ? columnWidth[index] : ''),
            flex: columnWidth.length > 0 ? 'auto' : '1'
        }
    }

    // 运行轨迹
    gotoTrack = (row) => {
        // 选择当前行
        this.setState({
            selectedRow: row,
            selectedRowKey: row.id
        }, () => {
            Utils.showModal(this, "mapVisible", 1)
        })
    }

    render() {
        const { header, rowNum, isTrack } = this.state;
        const { data } = this.props;
        
        return (
            <div className="bc-scrollBoard-box">
                <div className="scrollBoard-list scrollBoard-header">
                {
                    header.map((item, index) => <div key={index} className="scrollBoard-item" style={this.getStyle(index)}>{item}</div>)
                }
                </div>
                <div style={{height: 32*rowNum+'px'}}>
                {
                    data.length > rowNum ? 
                    
                        <Swiper
                            height={this.getRem(36*rowNum)}
                            modules={[Autoplay]}
                            slidesPerView='auto'
                            loopedSlides={rowNum+2}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false
                            }}
                            loop={true}
                            direction={"vertical"}
                        >
                            {
                                data.map((item, index) => <SwiperSlide key={index}>
                                    <div className="scrollBoard-list">
                                    {
                                        item.map((itemin, indexin) => 
                                        isTrack && itemin.type === 'button' ? 
                                        <div key={indexin} className="scrollBoard-item" style={this.getStyle(indexin)}><span className="scrollBoard-btn" onClick={() => this.gotoTrack(itemin)}>查看轨迹</span></div>
                                        : <div key={indexin} className="scrollBoard-item" style={this.getStyle(indexin)} dangerouslySetInnerHTML={{__html: itemin}}></div>)
                                    }
                                    </div>
                                </SwiperSlide>)
                            }
                        </Swiper>
                    : <div>
                    {
                        data.map((item, index) => <div key={index} className="scrollBoard-list">
                        {
                            item.map((itemin, indexin) => 
                            isTrack && itemin.type === 'button' ? 
                                <div key={indexin} className="scrollBoard-item" style={this.getStyle(indexin)}><span className="scrollBoard-btn" onClick={() => this.gotoTrack(itemin)}>查看轨迹</span></div>
                                : <div key={indexin} className="scrollBoard-item" style={this.getStyle(indexin)} dangerouslySetInnerHTML={{__html: itemin}}></div>)
                        }
                        </div>)
                    }
                    </div>
                }
                </div>
                
                {/* 查看运行轨迹 */}
                {this.state.mapVisible ? <MapTrack visible={this.state.mapVisible} isBigScreen={true} callbackParent={params => Utils.hideModal(this, params)} initForm={this.state.selectedRow}></MapTrack> : null}
            </div>
        )
    }
}

export default injectIntl(SwiperScrollBoard)