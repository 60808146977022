import Ajax from "./Fecth"

export default {
    login(params){  // 登录
        return Ajax.ajaxs("/admin/login", params);
    },
    logout(){   // 退出登录
        return Ajax.ajaxs("/admin/logout");
    },
    index: {  // 首页
        getUserNum(params){   // 统计用户人数
            return Ajax.ajaxs("/index/countTotal", params);
        },
        getUseAppNum(params){   // 统计最近使用人数
            return Ajax.ajaxs("/index/getUseAppNum", params);
        },
        findAppList(params){   // app列表
            return Ajax.ajaxs("/index/findAppList", params)
        },
        addApk(params){   // 新增app
            return Ajax.uploadFile("/index/addApk", params)
        },
        updateApk(params){   // 修改app
            return Ajax.ajaxs("/index/updateApk", params)
        },
        delApk(params){   // 删除app
            return Ajax.ajaxs("/index/delApk", params);
        },
        findClientInfo(params){   // 获取平台信息
            return Ajax.ajaxs("/index/findClientInfo", params);
        },
        exportTotalReport(params){   // 导出统计报表
            return Ajax.ajaxs("/index/exportTotalReport", params, 'GET');
        },
        getUseFunNum(params){   // 统计最近使用信息人数
            return Ajax.ajaxs("/index/getUseFunNum", params);
        },
        getFrequencyOfUse(params){   // 统计工作台使用
            return Ajax.ajaxs("/index/getFrequencyOfUse", params);
        },
    },
    user: {
        existName(params){   // 判断用户账号是否已注册
            return Ajax.ajaxs("/user/existName", params);
        },
        existPhone(params){  // 判断用户手机号是否已注册
            return Ajax.ajaxs("/user/existPhone", params);
        },
        existCardNum(params){  // 验证身份证号码是否存在
            return Ajax.ajaxs("/user/existCardNum", params)
        },
        existIccid(params){  // 验证ICCID是否存在
            return Ajax.ajaxs("/user/existIccid", params)
        },
        existImei(params){  // 验证IMEI是否存在
            return Ajax.ajaxs("/user/existImei", params)
        },
        existImsi(params){  // 验证IMSI是否存在
            return Ajax.ajaxs("/user/existImsi", params)
        },
        list(params){    // 获取用户列表
            return Ajax.ajaxs("/user/list", params);
        },
        add(params){  // 新增用户
            return Ajax.ajaxs("/user/add", params);
        },
        addUserList(params){  // 批量新增用户
            return Ajax.ajaxs("/user/addUserList", params);
        },
        update(params){  // 修改用户
            return Ajax.ajaxs("/user/update", params);
        },
        delete(params){   // 删除用户
            return Ajax.ajaxs("/user/delete", params);
        },
        exportExcel(params){   // 导出用户数据
            return Ajax.ajaxs("/user/exportExcel", params, 'GET');
        },
        findUserRoleList(params){
            return Ajax.ajaxs("/user/findUserRoleList", params);
        },
        getUserByGroupIds(params){  // 根据指定用户组下的用户ID
            return Ajax.ajaxs("/user/getUserByGroupIds", params);
        },
        resetPwd(params){  // 重置密码
            return Ajax.ajaxs("/user/resetPwd", params);
        },
        getPhoneGroups(params){  // 编辑页显示可查看单位通讯录
            return Ajax.ajaxs("/user/getPhoneGroups", params);
        },
        batchUpdate(params){  // 批量修改用户所在部门
            return Ajax.ajaxs("/user/batchUpdate", params);
        },
    },
    role: {
        list(params){   // 获取角色列表
            return Ajax.ajaxs("/role/list", params)
        },
        add(params){   // 新增角色
            return Ajax.ajaxs("/role/add", params)
        },
        // queryRoleMenu(params){   // 获取角色的菜单权限列表
        //     return Ajax.ajaxs("/role/queryRoleMenu", params)
        // },
        // saveRoleMenu(params){   // 设置/修改角色的菜单权限列表
        //     return Ajax.ajaxs("/role/saveRoleMenu", params)
        // },
        update(params){   // 修改角色
            return Ajax.ajaxs("/role/update", params)
        },
        delete(params){  // 删除角色
            return Ajax.ajaxs("/role/delete", params)
        },
        existName(params){  // 检查角色重名
            return Ajax.ajaxs("/role/existName", params)
        },
        getRole(params){   // 获取角色
            return Ajax.ajaxs("/role/getRole", params, 'GET')
        },
        roleAddUser(params){   // 角色关联用户
            return Ajax.ajaxs("/role/roleAddUser", params)
        },
        roleDelUser(params){   // 角色移除用户
            return Ajax.ajaxs("/role/roleDelUser", params)
        },
        roleUserList(params){   // 查询角色下的用户
            return Ajax.ajaxs("/role/roleUserList", params)
        },
        configRoleUserGroup(params){   // 角色设置用户管理范围
            return Ajax.ajaxs("/role/configRoleUserGroup", params)
        },
    },
    admin: {
        add(params){   // 新增管理员
            return Ajax.ajaxs("/admin/add", params)
        },
        existName(params){   // 判断管理员账号是否已存在
            return Ajax.ajaxs("/admin/existName", params)
        },
        list(params){   // 获取管理员列表
            return Ajax.ajaxs("/admin/list", params)
        },
        update(params){  //  修改管理员信息
            return Ajax.ajaxs("/admin/update", params)
        },
        delete(params){  //  删除管理员信息
            return Ajax.ajaxs("/admin/delete", params)
        },
        enableAdmin(params){  //  激活管理员
            return Ajax.ajaxs("/admin/enableAdmin", params)
        },
        findAdmin(params){  //  查询管理员信息
            return Ajax.ajaxs("/admin/findAdmin", params)
        },
        updatePassword(params){  //  修改管理员密码
            return Ajax.ajaxs("/admin/updatePassword", params)
        },
        findPlatFormInfo(params){  //  查询平台信息
            return Ajax.ajaxs("/admin/findPlatFormInfo", params)
        },
        uploadPlatformInfo(params){  //  修改平台信息
            return Ajax.uploadFile("/admin/uploadPlatformInfo", params)
        },
        findDefaultPwd(params){  //  查询默认密码
            return Ajax.ajaxs("/admin/findDefaultPwd", params)
        },
        updateDefaultPwd(params){  //  修改默认密码
            return Ajax.ajaxs("/admin/updateDefaultPwd", params)
        },
        existPhone(params){  //  检查账号是否已被占用
            return Ajax.ajaxs("/admin/existPhone", params)
        },
        existLoginName(params){  //  检查账号是否已被占用
            return Ajax.ajaxs("/admin/existLoginName", params)
        },
        getFeedbackList(params){  //  查询用户反馈
            return Ajax.ajaxs("/admin/getFeedbackList", params)
        },
        ipConfigList(params){  // 获取Ip配置列表
            return Ajax.ajaxs("/admin/ipConfigList", params, 'GET')
        },
        updateIpConfig(params){  // 修改Ip配置
            return Ajax.ajaxs("/admin/updateIpConfig", params)
        },
        findAdminSecurity(params){  // 查看管理员登陆安全项
            return Ajax.ajaxs("/admin/findAdminSecurity", params)
        },
        editAdminSecurity(params){  // 配置管理员登陆安全项
            return Ajax.ajaxs("/admin/editAdminSecurity", params)
        },
        updateResetPassword(params){  // 重置子管理员密码
            return Ajax.ajaxs("/admin/updateResetPassword", params)
        },
    },
    group: {
        list(params){   // 获取用户组列表
            return Ajax.ajaxs("/group/list", params);
        },
        groupList(params){   // 获取用户组列表(不带人数)
            return Ajax.ajaxs("/group/groupList", params);
        },
        add(params){   // 新增用户组
            return Ajax.ajaxs("/group/add", params);
        },
        existName(params){   // 判断用户组名称是否已存在
            return Ajax.ajaxs("/group/existName", params);
        },
        existDeptCode(params){   // 判断部门代码是否已存在
            return Ajax.ajaxs("/group/existDeptCode", params);
        },
        update(params){   // 修改用户组信息
            return Ajax.ajaxs("/group/update", params)
        },
        delete(params){   // 删除用户组
            return Ajax.ajaxs("/group/delete", params)
        },
        syncGyGroupData(){   // 同步用户组
            return Ajax.ajaxs("/group/syncGyGroupData")
        },
        getGroup(params){   // 获取用户组
            return Ajax.ajaxs("/group/getGroup", params, 'GET')
        },
        getQCodeInfo(params){  // 生成部门二维码
            return Ajax.ajaxs("/group/getQCodeInfo", params)
        },
        getQCodeInfoList(params){  // 批量打印部门二维码
            return Ajax.ajaxs("/group/getQCodeInfoList", params)
        },
        addGroupList(params){  // 批量导入单位接口
            return Ajax.ajaxs("/group/addGroupList", params)
        },
        existBathDeptCode(params){  // 批量判断标准代码是否重复
            return Ajax.ajaxs("/group/existBathDeptCode", params)
        },
        getLocationByAddress(params){  // 根据详细地址获取高德地图坐标
            return Ajax.ajaxs("/group/getLocationByAddress", params)
        },
        getLastDeptCode(params){  // 警务室下最小应急单位自动生成部门代码接口
            return Ajax.ajaxs("/group/getLastDeptCode", params)
        },
    },
    lic: {
        add(params){   // 上传license
            return Ajax.uploadFile("/lic/add", params)
        },
        get(){   // 获取license信息
            return Ajax.ajaxs("/lic/get")
        }
    },
    logs: {
        adminOperList(params){   // 管理员操作日志
            return Ajax.ajaxs("/log/adminOperList", params)
        },
        exportAdminOperLog(params){   // 导出平台操作日志
            return Ajax.ajaxs("/log/exportAdminOperLog", params)
        },
    },
    notice: {
        list(params){   // 查询公告
            return Ajax.ajaxs("/notice/list", params)
        },
        add(params){   // 新增公告
            return Ajax.uploadFile("/notice/add", params)
        },
        update(params){   // 修改公告
            return Ajax.uploadFile("/notice/update", params)
        },
        delete(params){   // 删除公告内容
            return Ajax.ajaxs("/notice/delete", params)
        },
        findNoticeList(params){   // 查询资讯类型
            return Ajax.ajaxs("/notice/findNoticeList", params)
        },
        addNotice(params){   // 新增资讯类型
            return Ajax.ajaxs("/notice/addNotice", params)
        },
        updateNotice(params){   // 修改资讯类型
            return Ajax.ajaxs("/notice/updateNotice", params)
        },
        moveNotice(params){   // 资讯类型移位
            return Ajax.ajaxs("/notice/moveNotice", params)
        },
        delNotice(params){   // 删除资讯类型
            return Ajax.ajaxs("/notice/delNotice", params)
        },
        existNoticeName(params){   // 判断名称是否存在
            return Ajax.ajaxs("/notice/existNoticeName", params)
        },
        updateRichTextImg(params){   // 上传富文本图片
            return Ajax.uploadFile("/notice/updateRichTextImg", params)
        },
        downloadUrlImg(params){   // 下载网络图片
            return Ajax.ajaxs("/notice/downloadUrlImg", params)
        },
        uploadVideo(params){   // 上传视频
            return Ajax.uploadFile("/notice/uploadVideo", params)
        },
    },
    workbench: {
        list (params){   // 查询工作台微应用
            return Ajax.ajaxs("/workbench/list", params)
        },
        add (params){   // 新增工作台微应用
            return Ajax.uploadFile("/workbench/add", params)
        },
        update (params){   // 修改工作台微应用
            return Ajax.uploadFile("/workbench/update", params)
        },
        delete (params){   // 删除工作台微应用
            return Ajax.ajaxs("/workbench/delete", params)
        },
        existWorkbenchAppName (params){   // 判断应用名称是否已存在
            return Ajax.ajaxs("/workbench/existWorkbenchAppName", params)
        },
        saveWorkBranchAuthority (params){   // 保存/修改轻应用可见权限
            return Ajax.ajaxs("/workbench/saveWorkbranchAuthority", params)
        },
        queryWorkBranchAuthority(params){  // 查询轻应用可见权限
            return Ajax.ajaxs("/workbench/queryWorkbranchAuthority", params)
        }
    },
    msg: {
        countNoticeMessage(params){   // 未读消息个数
            return Ajax.ajaxs("/log/countNoticeMessage", params);
        },
        findNoticeMessageList(params){   // 消息列表
            return Ajax.ajaxs("/log/findNoticeMessageList", params);
        },
        updateNoReadMsgList(params){   // 平台未读消息设为已读
            return Ajax.ajaxs("/log/updateNoReadMsgList", params);
        },
    },
    file: {
        add(params, progress){   // 上传文件
            return Ajax.uploadFile("/file/add", params, progress);
        },
        delete(params){   // 删除文件
            return Ajax.ajaxs("/file/delete", params);
        },
        update(params){   // 更新文件
            return Ajax.ajaxs("/file/update", params);
        },
        list(params){   // 查询文件列表
            return Ajax.ajaxs("/file/backendList", params);
        },
        getFilePath(params){   // 获取文件路径
            return Ajax.ajaxs("/file/getFilePath", params);
        },
        existName(params){   // 判断文件或文件夹是否重名
            return Ajax.ajaxs("/file/checkFile", params);
        },
        getFileByName(params){   // 通过文件名查询
            return Ajax.ajaxs("/file/getBackendFileByName", params);
        },
        download(params) {  // 下载文件
            let url = params.url && params.url.indexOf('/') === 0 ? params.url.substring(1) : params.url;
            return Ajax.ajaxsForFile("/file/download/" + url)
        },
        preview(params) {  // 预览图片
            let url = params.url && params.url.indexOf('/') === 0 ? params.url.substring(1) : params.url;
            return Ajax.ajaxsForFile("/file/preview/" + url)
        }
    },
    folder: {
        list(params){   // 查询文件列表
            return Ajax.ajaxs("/folder/list", params);
        },
        add(params){  // 添加文件夹
            return Ajax.ajaxs("/folder/add", params);
        },
        update(params){  // 修改文件夹
            return Ajax.ajaxs("/folder/update", params);
        },
        delete(params){   // 删除文件夹
            return Ajax.ajaxs("/folder/delete", params);
        },
    },
    appType: {
        list(params){   // 获取应用类型列表
            return Ajax.ajaxs("/appType/list", params);
        },
        add(params){   // 新增应用类型
            return Ajax.ajaxs("/appType/add", params)
        },
        update(params){   // 修改应用类型
            return Ajax.ajaxs("/appType/update", params)
        },
        move(params){   // 应用类型移位
            return Ajax.ajaxs("/appType/moveAppType", params)
        },
        delete(params){   // 删除应用类型
            return Ajax.ajaxs("/appType/delete", params)
        },
        existName(params){   // 判断名称是否存在
            return Ajax.ajaxs("/appType/existAppTypeName", params)
        },
    },
    apps: {
        list(params){   // 查询应用
            return Ajax.ajaxs("/apps/listAppByType", params)
        },
        add(params){   // 新增应用
            return Ajax.uploadFile("/apps/add", params)
        },
        update(params){   // 修改应用
            return Ajax.ajaxs("/apps/update", params)
        },
        delete(params){   // 删除应用
            return Ajax.ajaxs("/apps/delete", params)
        },
        allVersion(params){   // 获取某个应用的全部版本
            return Ajax.ajaxs("/apps/getAppListByPackageName", params)
        },
    },
    flow: {
        findDeptUser(params){   // 获取部门人员信息
            return Ajax.ajaxs("/flow/findDeptUser", params, 'GET')
        },
        listFormDesign(params){   // 获取流程列表
            return Ajax.ajaxs("/flow/listFormDesign", params)
        },
        addFormDesign(params){   // 新增审批流程
            return Ajax.ajaxs("/flow/addFormDesign", params, 'GET')
        },
        delFormDesign(params){   // 删除表单
            return Ajax.ajaxs("/flow/delFormDesign", params)
        },
        releaseForm(params){   // 发布/废弃表单流程
            return Ajax.ajaxs("/flow/releaseForm", params)
        },
    },
    dictConfig: {
        list(params){   // 数据字典分页查询
            return Ajax.ajaxs("/dictConfig/list", params)
        },
        add(params){
            return Ajax.ajaxs("/dictConfig/add", params)
        },
        getDictByTypeCode(params){    // 根据业务类型查询字典配置
            return Ajax.ajaxs("/dictConfig/getDictByTypeCode", params)
        },
        update(params){
            return Ajax.ajaxs("/dictConfig/update", params)
        },
        delete(params){
            return Ajax.ajaxs("/dictConfig/delete", params)
        }
    },
    help: {
        list(params){   // 帮助中心问题查询
            return Ajax.ajaxs("/help/list", params)
        },
        add(params){   // 帮助中心问题新增
            return Ajax.ajaxs("/help/add", params)
        },
        update(params){   // 帮助中心问题修改
            return Ajax.ajaxs("/help/update", params)
        },
        delete(params){   // 帮助中心问题删除
            return Ajax.ajaxs("/help/delete", params)
        },
    },
    // 系统配置
    sysconfig: {
        list(params){   // 查询系统配置列表
            return Ajax.ajaxs("/sysconfig/list", params, 'GET')
        },
        update(params){   // 修改系统配置
            return Ajax.ajaxs("/sysconfig/update", params)
        },
        // getConfigByKey(params){   // 查询系统配置列表
        //     return Ajax.ajaxs("/sysconfig/getConfigByKey", params)
        // }
    },

    attenceSchedule: {
        list(params){   // 查询班次列表
            return Ajax.ajaxs("/attenceSchedule/list", params)
        },
        add(params){   // 新增班次
            return Ajax.ajaxs("/attenceSchedule/add", params)
        },
        update(params){   // 更新班次信息
            return Ajax.ajaxs("/attenceSchedule/update", params)
        },
        delete(params){   // 删除班次
            return Ajax.ajaxs("/attenceSchedule/delete", params, 'GET')
        },
    },
    attenceGroup: {
        list(params){   // 查询考勤组列表
            return Ajax.ajaxs("/attenceGroup/list", params)
        },
        add(params){   // 新增考勤组
            return Ajax.ajaxs("/attenceGroup/add", params)
        },
        update(params){   // 修改考勤组
            return Ajax.ajaxs("/attenceGroup/update", params)
        },
        delete(params){   // 删除考勤组
            return Ajax.ajaxs("/attenceGroup/delete", params, 'GET')
        },
        getAttenceGroupDetail(params){   // 查询考勤组明细
            return Ajax.ajaxs("/attenceGroup/getAttenceGroupDetail", params, 'GET')
        },
        listHolidaty(params){   // 查询节假日
            return Ajax.ajaxs("/attenceGroup/listHolidaty", params)
        },
        addHolidaty(params){   // 新增节假日
            return Ajax.ajaxs("/attenceGroup/addHolidaty", params)
        },
        updateHolidaty(params){   // 修改节假日
            return Ajax.ajaxs("/attenceGroup/updateHolidaty", params)
        },
        deleteHolidaty(params){   // 删除节假日
            return Ajax.ajaxs("/attenceGroup/deleteHolidaty", params, 'GET')
        },
        existAttenceGroupPerson(params){   // 校验考勤组人员
            return Ajax.ajaxs("/attenceGroup/existAttenceGroupPerson", params)
        },
        immediatelyAttenceGroup(params){   // 立即生效修改的考勤组
            return Ajax.ajaxs("/attenceGroup/immediatelyAttenceGroup", params, 'GET')
        },
    },
    attenceRule: {
        list(params){   // 查询请假规则
            return Ajax.ajaxs("/attenceRule/list", params, 'GET')
        },
        add(params){   // 新增请假规则
            return Ajax.ajaxs("/attenceRule/add", params)
        },
        update(params){   // 更新请假规则
            return Ajax.ajaxs("/attenceRule/update", params)
        },
        delete(params){   // 删除请假规则
            return Ajax.ajaxs("/attenceRule/delete", params, 'GET')
        },
    },
    attence: {
        statsticOverTimeLength(params){   // 请假统计
            return Ajax.ajaxs("/attence/statsticOverTimeLength", params)
        },
        exportOverTimeLength(params){   // 导出日志统计
            return Ajax.ajaxs("/attence/exportOverTimeLength", params)
        },
    },
    holiday: {
        addHolidayList(params){   // 批量新增节假日
            return Ajax.ajaxs("/holiday/addHolidayList", params)
        },
        checkHolidayList(params){   // 检查法定节假日是否重复
            return Ajax.ajaxs("/holiday/checkHolidayList", params)
        },
        getCanlendar(params){   // 查询日历
            return Ajax.ajaxs("/holiday/getCanlendar", params)
        },
    },
    sensitiveWord: {
        add(params){   // 新增敏感词
            return Ajax.ajaxs("/sensitiveWord/add", params)
        },
        list(params){   // 查询敏感词
            return Ajax.ajaxs("/sensitiveWord/list", params, 'GET')
        },
    },
    watermark: {
        update(params){   // 更新水印设置
            return Ajax.ajaxs("/watermark/update", params)
        },
        findByCompany(params){   // 查询水印设置
            return Ajax.ajaxs("/watermark/findByCompany", params)
        },
    },
    work: {
        exportStatisticsWorkLogDetail(params){   // 导出日志统计
            return Ajax.ajaxs("/work/exportStatisticsWorkLogDetail", params)
        },
    },
    clockin: {
        queryClockinQuotationsList(params){   // 分页查询打卡语录
            return Ajax.ajaxs("/clockin/queryClockinQuotationsList", params)
        },
        addClockinQuotations(params){   // 新增打卡语录
            return Ajax.ajaxs("/clockin/addClockinQuotations", params)
        },
        updateClockinQuotations(params){   // 修改打卡语录
            return Ajax.ajaxs("/clockin/updateClockinQuotations", params)
        },
        deleteClockinQuotations(params){   // 删除打卡语录
            return Ajax.ajaxs("/clockin/deleteClockinQuotations", params)
        },
        getAttenceClockinRecordList(params){   // 查询打卡记录
            return Ajax.ajaxs("/clockin/getAttenceClockinRecordList", params, 'GET')
        },
        exportAttenceClockinRecord(params){   // 导出打卡记录
            return Ajax.ajaxs("/clockin/exportAttenceClockinRecord", params, 'GET')
        },
        getClockinRecordListByDate(params){   // 查询每日统计
            return Ajax.ajaxs("/clockin/getClockinRecordListByDate", params, 'GET')
        },
        getClockinRecordListByMonth(params){   // 打卡记录月度统计
            return Ajax.ajaxs("/clockin/getClockinRecordListByMonth", params, 'GET')
        },
        exportClockinRecordListByDate(params){   // 导出每日统计
            return Ajax.ajaxs("/clockin/exportClockinRecordListByDate", params, 'GET')
        },
        exportClockinRecordListByMonth(params){   // 导出月度统计
            return Ajax.ajaxs("/clockin/exportClockinRecordListByMonth", params, 'GET')
        },
        getUserClockinRecordByDate(params){   // 查询用户某日考勤打卡记录
            return Ajax.ajaxs("/clockin/getUserClockinRecordByDate", params, 'GET')
        },
        getNonormalClockinRecord(params){   // 查询用户某日考勤打卡记录
            return Ajax.ajaxs("/clockin/getNonormalClockinRecord", params, 'GET')
        },
        queryMyApplyRecord(params){   // 查询我的请假/加班 审批记录
            return Ajax.ajaxs("/clockin/queryMyApplyRecord", params, 'GET')
        }
    },
    field: {
        list(params){   // 外勤打卡记录查询
            return Ajax.ajaxs("/field/list", params)
        },
        exportFieldPunchin(params){   // 导出打卡记录
            return Ajax.ajaxs("/field/exportFieldPunchin", params, 'GET')
        },
        countUsers(params){   // 统计当前管理员下用户数量
            return Ajax.ajaxs("/field/countUsers", params)
        },
        countFieldPunchins(params){   // 统计当天该管理员下有多少人打卡
            return Ajax.ajaxs("/field/countFieldPunchins", params)
        },
    },
    patrol: {  // 巡逻报备
        list(params){   // 巡逻记录
            return Ajax.ajaxs("/patrol/list", params)
        },
        recordList(params){   // 查看轨迹
            return Ajax.ajaxs("/patrol/recordList", params)
        },
        exportPatrolRecord(params){   // 导出巡逻报表
            return Ajax.ajaxs("/patrol/exportPatrolRecord", params, 'GET')
        },
        allRecordList(params){   // 大屏数据
            return Ajax.ajaxs("/patrol/allRecordList", params)
        },
        countPatrols(params){   // 统计当天该管理员下巡逻人数
            return Ajax.ajaxs("/patrol/countPatrols", params)
        },
        patroltotal(params){   // 巡逻统计
            return Ajax.ajaxs("/patrol/patroltotal", params)
        },
        exportPatrolTotalEntity(params){   // 导出巡逻统计
            return Ajax.ajaxs("/patrol/exportPatrolTotalEntity", params, 'GET')
        },
        getListPatrolRecord(params){   // 上报记录列表
            return Ajax.ajaxs("/patrol/getListPatrolRecord", params)
        },
    },
    casemaster: {  // 处警报备
        allRecordList(params){   // 大屏数据
            return Ajax.ajaxs("/casemaster/allRecordList", params)
        },
        recordList(params){   // 单个处警明细
            return Ajax.ajaxs("/casemaster/recordList", params)
        },
        list(params){   // 处警记录
            return Ajax.ajaxs("/casemaster/list", params)
        },
        exportCaseMasterEntity(params){   // 导出报表
            return Ajax.ajaxs("/casemaster/exportCaseMasterEntity", params, 'GET')
        },
        casetotal(params){   // 处警统计
            return Ajax.ajaxs("/casemaster/casetotal", params)
        },
        exportCaseTotalEntity(params){   // 导出处警统计
            return Ajax.ajaxs("/casemaster/exportCaseTotalEntity", params, 'GET')
        },
    },
    reportmaster: {   // 防疫报备
        allRecordList(params){   // 地图数据
            return Ajax.ajaxs("/reportmaster/allRecordList", params)
        },
        recordList(params){   // 单个报备详情
            return Ajax.ajaxs("/reportmaster/recordList", params)
        },
        list(params){   // 报备记录
            return Ajax.ajaxs("/reportmaster/list", params)
        },
        exportReportMasterEntity(params){   // 导出报表
            return Ajax.ajaxs("/reportmaster/exportReportMasterEntity", params, 'GET')
        },
        reportTotal(params){   // 防疫统计
            return Ajax.ajaxs("/reportmaster/reportTotal", params)
        },
        exportReportTotal(params){   // 防疫统计报表
            return Ajax.ajaxs("/reportmaster/exportReportTotal", params, 'GET')
        },
        remoteReport(params){   // 异地报备记录
            return Ajax.ajaxs("/reportmaster/remoteReport", params)
        },
        exportRemoteReport(params){   // 导出异地报备记录
            return Ajax.ajaxs("/reportmaster/exportRemoteReport", params, 'GET')
        },
        addressName(params){   // 查询异地报备记录配置
            return Ajax.ajaxs("/reportmaster/addressName", params)
        },
        setAddressName(params){   // 设置异地报备记录配置
            return Ajax.ajaxs("/reportmaster/setAddressName", params)
        },
    },
    netdisk: {   // 网盘
        list(params){   // 网盘列表
            return Ajax.ajaxs("/netdisk/list", params)
        },
        getChildFloderlist(params){   // 查看云盘文件夹路径
            return Ajax.ajaxs("/netdisk/getChildFloderlist", params)
        },
        editUserDiskSize(params){   // 配置个人网盘额度
            return Ajax.ajaxs("/netdisk/editUserDiskSize", params)
        },
    },
    general: {
        login(params){   // 普通用户登陆后台
            return Ajax.ajaxs("/general/login", params)
        },
        netdiskList(params){   // 查看个人网盘数据
            return Ajax.ajaxs("/general/netdiskList", params)
        },
        getChildFloderlist(params){   // 查看文件夹路径
            return Ajax.ajaxs("/general/getChildFloderlist", params)
        },
        findPlatFormInfoUser(params){  // 查询平台信息
            return Ajax.ajaxs("/general/findPlatFormInfoUser", params)
        },
        logout(){   // 退出登录
            return Ajax.ajaxs("/general/logout");
        },
    },
    caseinfo: {
        list(params){   // 警情列表
            return Ajax.ajaxs("/caseinfo/list", params)
        },
        add(params){   // 新增警情
            return Ajax.ajaxs("/caseinfo/add", params)
        },
        update(params){   // 修改警情
            return Ajax.ajaxs("/caseinfo/update", params)
        },
        delete(params){   // 删除警情
            return Ajax.ajaxs("/caseinfo/delete", params)
        },
        findCaseUsers(params){   // 查找处警待命状态的民警列表
            return Ajax.ajaxs("/caseinfo/findCaseUsers", params)
        },
        orderUser(params){   // 警情任务分派
            return Ajax.ajaxs("/caseinfo/orderUser", params)
        },
        orderUserRes(params){   // 查看警情分派记录
            return Ajax.ajaxs("/caseinfo/orderUserRes", params)
        },
    },
    meet: {
        meetingRoomlist(params){    // 获取会议室列表
            return Ajax.ajaxs("/meeting/meetingRoomlist", params)
        },
        addMeetingRoom(params){   // 添加会议室
            return Ajax.uploadFile("/meeting/addMeetingRoom", params)
        },
        updateMeetingRoom(params){   // 修改会议室
            return Ajax.uploadFile("/meeting/updateMeetingRoom", params)
        },
        deleteMeetingRoom(params){    // 删除会议室
            return Ajax.ajaxs("/meeting/deleteMeetingRoom", params)
        },
        meetingScheduledlist(params){   // 获取会议室预定记录
            return Ajax.ajaxs("/meeting/meetingScheduledlist", params)
        },
        queryScheduledTime(params){  // 查询会议室预定时段
            return Ajax.ajaxs("/meetingRoom/queryScheduledTime", params)
        }
    },
    userplace: {
        list(params){  // 地址采集列表
            return Ajax.ajaxs("/userplace/list", params)
        },
        export(params){  // 导出地址采集列表
            return Ajax.ajaxs("/userplace/export", params, 'GET')
        }
    },
    bigData: {  //大屏数据
        getUserNum(params){  // 人员统计
            return Ajax.ajaxs("/bigData/getUserNum", params)
        },
        getDeptUserNum(params){  // 部门人员统计列表
            return Ajax.ajaxs("/bigData/getDeptUserNum", params)
        },
        getOnDutyMap(params){  // 执勤地图接口
            return Ajax.ajaxs("/bigData/getOnDutyMap", params)
        },
        getCaseList(params){  // 今日警情监听
            return Ajax.ajaxs("/bigData/getCaseList", params)
        },
        getCaseNum(params){  // 今日警情分布
            return Ajax.ajaxs("/bigData/getCaseNum", params)
        },
        getDutyNum(params){  // 警务人员执勤分布
            return Ajax.ajaxs("/bigData/getDutyNum", params)
        },
        getEfficiency(params){  // 今日处警效率分析
            return Ajax.ajaxs("/bigData/getEfficiency", params)
        },
    },
    visit: {  //拜访
        list(params){  // 拜访打卡
            return Ajax.ajaxs("/visit/list", params)
        },
        exportVisit(params){  // 导出拜访打卡
            return Ajax.ajaxs("/visit/exportVisit", params, 'GET')
        },
        getCheckInSettings(params){  // 拜访设置
            return Ajax.ajaxs("/visit/getCheckInSettings", params)
        },
        selectVisitConfig(params){  // 查询拜访设置
            return Ajax.ajaxs("/visit/selectVisitConfig", params)
        },
    },
    project: {
        selectArea(params){  // 查询省份列表
            return Ajax.ajaxs("/project/selectArea", params)
        },
        addProjectArea(params){  // 添加区域
            return Ajax.ajaxs("/project/addProjectArea", params)
        },
        upProjectArea(params){  // 修改区域
            return Ajax.ajaxs("/project/upProjectArea", params)
        },
        delProjectArea(params){  // 删除区域
            return Ajax.ajaxs("/project/delProjectArea", params)
        },
        list(params){  // 区域列表
            return Ajax.ajaxs("/project/list", params)
        },
        selectProjectAdmin(params){  // 查询监督领导
            return Ajax.ajaxs("/project/selectProjectAdmin", params)
        },
        addProjectAdmin(params){  // 添加监督领导
            return Ajax.ajaxs("/project/addProjectAdmin", params)
        },
        deleteProjectAdmin(params){  // 删除监督领导
            return Ajax.ajaxs("/project/deleteProjectAdmin", params)
        },
        getSalesManager(params){  // 查询某区域的销售列表
            return Ajax.ajaxs("/project/getSalesManager", params)
        },
        delSalesManager(params){  // 删除销售列表
            return Ajax.ajaxs("/project/delSalesManager", params)
        },
        addSalesManager(params){  // 新增销售
            return Ajax.ajaxs("/project/addSalesManager", params)
        },
        getProjectList(params){  // 查询项目列表
            return Ajax.ajaxs("/project/getProjectList", params)
        },
        getProjectAll(params){  // 项目详情
            return Ajax.ajaxs("/project/getProjectAll", params)
        },
        getStartProject(params){  // 查询进行中项目
            return Ajax.ajaxs("/project/getStartProject", params)
        },
        getEndProject(params){  // 查询已结束项目
            return Ajax.ajaxs("/project/getEndProject", params)
        },
        exportList(params){  // 导出区域列表
            return Ajax.ajaxs("/project/exportList", params)
        },
        exportGetProjectList(params){  // 导出项目列表
            return Ajax.ajaxs("/project/exportGetProjectList", params)
        },
    },
    equipment: {
        list(params){  // 装备列表查询
            return Ajax.ajaxs("/equipage/list", params)
        },
        upQuantity(params){  // 修改库存
            return Ajax.ajaxs("/equipage/upQuantity", params)
        },
        saveQuantity(params){  // 查询库存
            return Ajax.ajaxs("/equipage/saveQuantity", params)
        },
        delete(params){  // 删除装备
            return Ajax.ajaxs("/equipage/delete", params)
        },
        add(params){  // 添加装备
            return Ajax.uploadFile("/equipage/upLoadFile", params)
        },
        update(params){  // 修改装备信息
            return Ajax.uploadFile("equipage/upEquipage", params)
        },
        saveEquipageList(params){  // 装备领取记录列表
            return Ajax.ajaxs("/equipage/saveEquipageList", params)
        },
        upEquipageStatus(params){  // 装备上架/下架
            return Ajax.ajaxs("/equipage/upEquipageStatus", params)
        },
        exportList(params){  // 导出装备管理列表
            return Ajax.ajaxs("/equipage/exportList", params)
        },
        exportEquipageList(params){  // 导出领取装备记录
            return Ajax.ajaxs("/equipage/exportEquipageList", params)
        },
        confirmReceipt(params){  // 确认领取装备
            return Ajax.ajaxs("/equipage/confirmReceipt", params)
        },
    },
    baAttendance: {
        savePlace(params) {  // 打卡地点列表
            return Ajax.ajaxs("/baAttendance/savePlace", params)
        },
        addPlace(params) {  // 新增打卡地点
            return Ajax.ajaxs("/baAttendance/addPlace", params)
        },
        upPlace(params) {  // 编辑打卡地点
            return Ajax.ajaxs("/baAttendance/upPlace", params)
        },
        delPlace(params) {  // 删除打卡地点
            return Ajax.ajaxs("/baAttendance/delPlace", params)
        },
        list(params) {  // 打卡记录
            return Ajax.ajaxs("/baAttendance/list", params)
        },
        exportList(params) {  // 导出打卡记录
            return Ajax.ajaxs("/baAttendance/exportList", params)
        },
    },
    other: {
        fileUpload(params) {  // 预处理-文件预览用
            return Ajax.uploadFileReader("/fileUpload", params)
        },
    },
    screenShow: {
        checkAccount(params) {  // 大屏登录
            return Ajax.ajaxs("/screenShow/checkAccount", params)
        },
        countTotal(params) {  // 大屏统计数量接口
            return Ajax.ajaxs("/screenShow/countTotal", params)
        },
        countUserClockin(params) {  // 在线上班人数统计
            return Ajax.ajaxs("/screenShow/countUserClockin", params)
        },
        countUsers(params) {  // 大屏统计总人数
            return Ajax.ajaxs("/screenShow/countUsers", params)
        },
        deptNumber(params) {  // 各部门在线人数和激活人数
            return Ajax.ajaxs("/screenShow/deptNumber", params)
        },
        historyOnWork(params) {  // 考勤记录分析
            return Ajax.ajaxs("/screenShow/historyOnWork", params)
        },
        patrolCountDay(params) {  // 大屏统计巡逻统计
            return Ajax.ajaxs("/screenShow/patrolCountDay", params)
        },
        patrolLeaderBoard(params) {  // 大屏统计巡逻排行榜
            return Ajax.ajaxs("/screenShow/patrolLeaderBoard", params)
        },
        mapRecordList(params){  // 大屏地图数据
            return Ajax.ajaxs("/screenShow/mapRecordList", params)
        }
    }
}