import React from 'react';
import { injectIntl } from "react-intl";
import * as echarts from "echarts";

let myChart;

class LineChartBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.props.data
		};
	}
	componentDidMount() {
		window.addEventListener('resize', this.chartRefresh);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.chartRefresh);
	}
	componentDidUpdate(prevProps) {
		if(JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
			this.setState({
				data: this.props.data
			}, () => {
				this.initChart();
			})
		}
	}

	chartRefresh = () => {
		setTimeout(() => {
			if (myChart !== null && myChart !== "" && myChart !== undefined) {
				myChart.resize();
			}
		}, 500)
	}

	initChart = () => {
		if (myChart !== null && myChart !== "" && myChart !== undefined) {
			myChart.dispose(); //销毁
		}

		var chartDom = document.getElementById("lineChartBar");
		myChart = echarts.init(chartDom);
		var option = {
			grid: {
				top: 50,
				right: 40
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'none'
				},
				formatter: function (params) {
					return params[0].name + '<br>' + params[0].value + '人在线';
				}
			},
			xAxis: {
				type: 'category',
				// boundaryGap: false,
				data: this.state.data.map(item => item.date),
				axisTick: {
					inside: true
				},
				axisLine: {
					lineStyle: {
						color: '#0e3454'
					}
				},
				axisLabel: {
					color: '#bbbecc',
					formatter: function (value, index) {
						return value.substring(5);
					}
				},
				offset: 6
			},
			yAxis: {
				type: 'value',
				axisTick: {
					show: true,
					inside: true
				},
				axisLine: {
					show: true,
					lineStyle: {
						color: '#0e3454'
					}
				},
				axisLabel: {
					color: '#bbbecc'
				},
				splitLine: {
					lineStyle: {
						color: '#0e3454'
					}
				}
			},
			series: [{
				data: this.state.data.map(item => item.onWorkNumber),
				type: 'line',
				showSymbol: false,
				symbol: 'circle',
				symbolSize: 7,
				color: '#22acfb',
				smooth: true,
				lineStyle: {
					color: '#22acfb',
					width: 3
				},
				areaStyle: {
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
						offset: 0,
						color: 'rgba(82, 247, 240, 0.2)'
					}, {
						offset: 1,
						color: 'rgba(82, 247, 240, 0)'
					}])
				}
			}, {
				name: 'hill',
				type: 'pictorialBar',
				barCategoryGap: '-30%',
				symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
				data: [{
					value: this.state.data[0].onWorkNumber,
					itemStyle: {
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							{ offset: 0, color: '#ff0303' },
							{ offset: 1, color: 'rgba(255, 3, 3, 0)' }
						])
					}
				}, {
					value: this.state.data[1].onWorkNumber,
					itemStyle: {
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							{ offset: 0, color: '#00ff4e' },
							{ offset: 1, color: 'rgba(0, 255, 78, 0)' }
						])
					}
				}, {
					value: this.state.data[2].onWorkNumber,
					itemStyle: {
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							{ offset: 0, color: '#ffa200' },
							{ offset: 1, color: 'rgba(255, 162, 0, 0)' }
						])
					}
				}, {
					value: this.state.data[3].onWorkNumber,
					itemStyle: {
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							{ offset: 0, color: '#03eaff' },
							{ offset: 1, color: 'rgba(3, 234, 255, 0)' }
						])
					}
				}, {
					value: this.state.data[4].onWorkNumber,
					itemStyle: {
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							{ offset: 0, color: '#ff00ea' },
							{ offset: 1, color: 'rgba(255, 0, 234, 0)' }
						])
					}
				}, {
					value: this.state.data[5].onWorkNumber,
					itemStyle: {
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							{ offset: 0, color: '#fff45c' },
							{ offset: 1, color: 'rgba(255, 244, 92, 0)' }
						])
					}
				}, {
					value: this.state.data[6].onWorkNumber,
					itemStyle: {
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							{ offset: 0, color: '#e4007f' },
							{ offset: 1, color: 'rgba(228, 0, 127, 0)' }
						])
					}
				}]
			}]
		};

		option && myChart.setOption(option);
	}

	render() {
		return <div id="lineChartBar" style={{ width: "100%", height: "100%" }}></div>
	}
}

export default injectIntl(LineChartBar)
