import {createStore} from 'redux'
import reducer from '../Reducer'
const initValue={
    'userGroup': [],  // 用户单位 树结构
    'platInfo': null,
    language: localStorage.getItem("locale") ? localStorage.getItem("locale") : navigator.language.split('-')[0],
    fresh: false,
    OAFormType: [],  // OA表单分类
}
const store=createStore(reducer,initValue)
export default store