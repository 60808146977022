import React from 'react'
import moment from 'moment';
import Utils from '@/utils/utils'


// let script = null;

/**
 * 添加点标记
 * @param {} point 
 */
const addMapMarker = (point, AMap, map) => {
    // console.log(point)
    var infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(0, -30)});

    // console.log(point.index)
    var markerContent = `<div class="custom-content-marker">
    <img src="${require(`@/assets/img/${point.icon}.png`)}"/>
    ${point.index ? `<div class="custom-marker-index">${point.index}</div>` : ''}
    </div>`
    
    // 创建一个 Marker 实例：
    const marker = new AMap.Marker({
        // 将一张图片的地址设置为 icon
        // icon: require(`@/assets/img/${point.icon}.png`),
        position: new AMap.LngLat(point.x, point.y),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: point.endTime,
        content: markerContent,
        offset: new AMap.Pixel(-15, -42) // 以 icon 的 [center bottom] 为原点
    });


    // 设置label标签
    // label默认蓝框白底左上角显示，样式className为：amap-marker-label
    // marker.setLabel({
    //     direction:'center',
    //     offset: new AMap.Pixel(10, 0),  //设置文本标注偏移量
    //     content: point.index, //设置文本标注内容
    // });

    marker.on('click', async (e) => {
        // 获取地址信息
        if(!point.address){
            point.address = await Utils.getAddress(AMap, point.x, point.y);
        }
        
        infoWindow.setContent(`<div style='padding:7px 0px 0px 0px;'>
        ${point.icon !== "start" ? `<p>移动${point.moveTimeText} / ${Utils.formatDistance(point.distance)}</p>` : ""}
        ${point.stopTime ? `<p>在此停留：${point.stopTimeText}（${point.startTime} ~ ${point.endTime}）</p>` : ""}
        <p style="color: #999;">${point.address}</p>
        </div>`) 
        
        infoWindow.open(map, e.target.getPosition());

        
    });
    // marker.emit('click', {target: marker});

    // 将创建的点标记添加到已有的地图实例：
    map.add(marker);
}



/**
 * 轨迹偏纠
 * @param {*} props 
 * @returns 
 */
const mapGrapRoad = (originPath, AMap, map) => {
    
    return new Promise((resolve, reject) => {
        // 添加点的tm
        let roadPath = originPath.map((item, index) => {
            if(index === 0){
                item.tm = moment(item.createtime).valueOf()/1000;
            }else{
                item.tm = (moment(item.createtime) - moment(originPath[0].createtime)) / 1000;
                
            }
            item.sp = 0;
            item.ag = 0;
            // 防止最后一个点的tm被下一次轨迹偏纠的第一个点修改
            return JSON.parse(JSON.stringify(item));
        })


        let path = [];
        AMap.plugin('AMap.GraspRoad',function(){
            var grasp = new AMap.GraspRoad();
            grasp.driving(roadPath,function(error,result){
                // console.log(error)
                if(!error){
                // 成功 返回偏纠后的位置点
                  var newPath = result.data.points;//纠偏后的轨迹
                //   console.log("轨迹偏纠返回的位置点：", newPath)

                    // 将原轨迹的起点和终点添加到偏纠后的轨迹中
                    newPath.unshift(roadPath[0]);
                    newPath.push(roadPath[roadPath.length - 1])

                    path = newPath.map(item => [item.x, item.y])
                    
                }else{
                    // console.log("轨迹偏纠失败，用原来的位置点：", roadPath)
                    // 失败 用原来的位置点
                    path = roadPath.map(item => [item.x, item.y])
                }
                

                
                resolve(path)
         })
      })
    })
    
}

// 轨迹绘制
const drawPointLine = (map, path, totalDistance) => {
    window.AMapUI.load(['ui/misc/PathSimplifier', 'lib/$'], function(PathSimplifier, $) {
        // console.log(PathSimplifier)
        if (!PathSimplifier.supportCanvas) {
            console.log('当前环境不支持 Canvas！');
            return;
        }

        var pathSimplifierIns = new PathSimplifier({
            zIndex: 100,
            //autoSetFitView:false,
            map: map, //所属的地图实例

            getPath: function(pathData, pathIndex) {

                return pathData.path;
            },
            getHoverTitle: function(pathData, pathIndex, pointIndex) {
                return pathData.path[pointIndex];
            },
            renderOptions: {
                // #
                renderAllPointsIfNumberBelow: 100, //绘制路线节点，如不需要可设置为-1
                pathLineStyle: {
                    lineWidth: 9,
                    strokeStyle: '#f7b538',
                    borderWidth: 1,
                    borderStyle: '#cccccc',
                    dirArrowStyle: true
                },
                pathLineHoverStyle: null,
                dirArrowStyle: {
                    stepSpace: 20,
                    strokeStyle: '#ffffff',
                    lineWidth: 3
                },
                pathNavigatorStyle: {
                    initRotateDegree: 0,
                    width: 16,
                    height: 16,
                    autoRotate: true,
                    lineJoin: 'round',
                    content: 'defaultPathNavigator',
                    fillStyle: '#087EC4',
                    strokeStyle: '#116394', //'#eeeeee',
                    lineWidth: 1,
                    pathLinePassedStyle: {
                        lineWidth: 9,
                        strokeStyle: '#0ea417',
                        borderWidth: 1,
                        borderStyle: '#eeeeee',
                        dirArrowStyle: true
                    }
                },
                keyPointStyle: {
                    radius: 4,
                    fillStyle: 'rgba(8, 126, 196, 1)',
                    lineWidth: 1,
                    strokeStyle: '#eeeeee'
                }
            }
        });

        window.pathSimplifierIns = pathSimplifierIns;

        //设置数据
        pathSimplifierIns.setData([{
            name: '',
            path: path
        }]);

        //对第一条线路（即索引 0）创建一个巡航器
        var navg1 = pathSimplifierIns.createPathNavigator(0, {
            loop: true, //循环播放
            speed: totalDistance/5 //巡航速度，单位千米/小时
        });

        navg1.start();
    });
}

// const loadAmapUiJs = () => {
//     return new Promise((resolve, reject) => {
//         //加载地图绘制的组件
//         var script = document.createElement("script");
//         document.body.appendChild(script);
//         script.src = "https://webapi.amap.com/ui/1.1/main.js?v=1.1.1";
//         script.onload = () => {
//             resolve(script)
//         };
//     })
    
// }





class TrackComponent extends React.Component{

    async componentDidMount(){
        // 加载amapui js库文件
        // script = await loadAmapUiJs()
        this.initTrack(this.props);
    }

    // componentWillUnmount(){
    //     document.body.removeChild(script);
    // }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps.originPath) !== JSON.stringify(this.props.originPath)){
            this.initTrack(this.props);
        }
    }


    initTrack = async (props) => {
        const AMap = window.AMap;
        const map = props.__map__;
        const originPath = props.originPath;  // 原始轨迹数据


        


        // 清除地图上的控件
        map.clearMap()
        // 清除地图上的信息窗体
        map.clearInfoWindow();

        // console.log("原始点：", originPath)

        // 过滤掉同一个位置的点
        let points = Utils.filterPoints(originPath);

        // console.log("过滤后的点：", points)

        // 轨迹偏纠原始数据点
        let grapPoint = [];
        // 最终绘制到地图的轨迹点集合
        let drawPoint = [];  
        let markIndex = 0;
        let startTime = 0;  // 设置开始时间，用于计算两个停留点之间花费的时间
        let totalDistance = 0; // 总共运行多少米，用来控制轨迹动画的速度
        for(let i = 0; i < points.length; i++){
            // 将点添加到轨迹偏纠队列
            grapPoint.push(points[i]);
            
            if(i === 0){
                points[i].icon = "start"
                startTime = points[i].createtime;
                // 添加点标记到地图
                addMapMarker(points[i], AMap, map)
            }else if(i === points.length - 1){  
                // 终点
                if(grapPoint.length > 1) {  
                    // 进行轨迹偏纠
                    let point = await mapGrapRoad(grapPoint, AMap, map);
                    drawPoint = drawPoint.concat(point);
                    // grapPointList.push(grapPoint);
                    // 清空grapPoint队列
                    grapPoint = [];
                }
                points[i].icon = "end"  
                // 计算移动时间
                points[i].moveTime = moment(points[i].createtime).valueOf() - moment(startTime).valueOf();
                points[i].moveTimeText = Utils.formatTimeAccount2(points[i].moveTime);
                // 添加点标记到地图
                addMapMarker(points[i], AMap, map)
            }else if(points[i].stopTime){  // 停留
                    // points[i].icon = "marker-stop"
                    // 进行轨迹偏纠
                    let point = await mapGrapRoad(grapPoint, AMap, map);
                    drawPoint = drawPoint.concat(point);
                    // grapPointList.push(grapPoint);
                    // 清空grapPoint队列，保留最后一个点
                    grapPoint = [grapPoint[grapPoint.length - 1]];

                    // 添加点标记到地图
                    points[i].icon = "marker-yellow"
                    markIndex++;
                    points[i].index = markIndex;
                    // 计算移动时间
                    points[i].moveTime = moment(points[i].createtime).valueOf() - moment(startTime).valueOf();
                    points[i].moveTimeText = Utils.formatTimeAccount2(points[i].moveTime);
                    startTime = points[i].endtime;
                    addMapMarker(points[i], AMap, map)
            }

            totalDistance += points[i].distance || 0

        }
        // 绘制轨迹
        drawPointLine(map, drawPoint, totalDistance);

        map.setFitView();
    }

    render(){
        return null
    }
} 

export default TrackComponent
