import React from "react";
import { injectIntl } from "react-intl";
import * as echarts from "echarts";

let myChart;

class Echarts3dBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            positionData: this.props.positionData, // 部门列表
			activeUserData: this.props.activeUserData, // 各部门激活人数
			onlineData: this.props.onlineData, // 各部门上班人数
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.chartRefresh);
    }
    componentWillUnmount() {
		window.removeEventListener('resize', this.chartRefresh);
	}
    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.onlineData) !== JSON.stringify(this.props.onlineData)) {
            this.setState({
                positionData: this.props.positionData, // 部门列表
                activeUserData: this.props.activeUserData, // 各部门激活人数
                onlineData: this.props.onlineData, // 各部门上班人数
            }, () => {
                this.initChart();
            })
        }
    }

    chartRefresh = () => {
        setTimeout(() => {
            if (myChart !== null && myChart !== "" && myChart !== undefined) {
                myChart.resize();
            }
        }, 500)
    }

    initChart = () => {
        if (myChart !== null && myChart !== "" && myChart !== undefined) {
            myChart.dispose(); //销毁
        }

        var chartDom = document.getElementById("threeDChart");
        myChart = echarts.init(chartDom);

        var option = {
            tooltip: {
                trigger: 'axis',
                confine: true,
                axisPointer: { type: 'none' },
                textStyle: { fontSize: 12, fontWeight: 500 },
                formatter(params) {
                    // 只展示柱子对应的内容，把顶部底部的 tooltip 过滤掉
                    return params.reduce((pre, i) => {
                        if (i.componentSubType === 'bar') {
                            i.marker = i.marker.replace(/\[object Object\]/, i.color.colorStops[1].color);
                            i.value = `<span style="flex: 1; text-align: right; margin-left: 16px;">${i.value}</span>`;
                            const current = `<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.seriesName} ${i.value}</div>`;
                            return `${pre}${current}`;
                        }
                        return pre;
                    }, '');
                },
            },
            legend: {
                data: ['激活人数', '上班人数'],
                right: 40,
                top: '10%',
                icon: 'rect',
                itemWidth: 8,
                itemHeight: 2,
                itemGap: 20,
                textStyle: {
                    color: '#dcf1fd'
                },
        
                // 因为柱子顶部和底部是通过 offset 跟柱子结合起来的，如果取消选中，柱子数量变化，又要重新计算 offset，为了简单点就直接禁掉了
                selectedMode: false,
            },
            grid: { top: '25%', left: '3%', right: '4%', bottom: '1%', containLabel: true },
            xAxis: {
                type: 'category',
                data: this.state.positionData,
                axisLine: { lineStyle: { color: '#0e3454' } },
                axisTick: { inside: true, alignWithLabel: true },
                axisLabel: { color: '#bbbecc', rotate: -45, margin: 15, width: 80, overflow: 'truncate' },
            },
            yAxis: {
                boundaryGap: [0, 0.01],
                type: 'value',
                axisLine: { show: true, lineStyle: { color: '#0e3454' } },
                axisTick: { show: true, inside: true },
                axisLabel: { color: '#bbbecc' },
                splitLine: { lineStyle: { color: '#0e3454' } }
            },
            series: [
                {
                    name: '激活人数',
                    type: 'bar',
                    barWidth: 12,
                    barGap: '17%',
                    data: this.state.activeUserData,
                    itemStyle: {
                        // 柱体渐变色
                        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                            {
                                offset: 0,
                                color: '#4bb0fe',
                            },
                            {
                                offset: 1,
                                color: '#04effe',
                            },
                        ])
                    },
                },
                {
                    name: '激活人数',
                    type: 'pictorialBar',
                    symbolSize: [12, 6],
        
                    // 这个属性很重要，直接决定了顶部跟柱子是否契合
                    symbolOffset: [-7, -3],
        
                    z: 12,
                    itemStyle: { color: '#0a7aa2' },
                    symbolPosition: 'end',
        
                    // 要给成武汉这两个柱子的值，这是圆柱顶部，值不给对高度会对不上
                    data: this.state.activeUserData,
                },
                {
                    name: '激活人数',
                    type: 'pictorialBar',
                    symbolSize: [12, 6],
                    symbolOffset: [-7, 3],
                    z: 12,
                    itemStyle: { color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                        {
                            offset: 0,
                            color: '#4bb0fe',
                        },
                        {
                            offset: 1,
                            color: '#04effe',
                        },
                    ]) },
                    symbolPosition: 'start',
        
                    // 这个给成大于 0 的值就行，设置了 position，一定会在柱子底部展示
                    data: this.state.activeUserData,
                },
                {
                    name: '上班人数',
                    type: 'bar',
                    barWidth: 12,
                    data: this.state.onlineData,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                            {
                                offset: 0,
                                color: '#f572d3',
                            },
                            {
                                offset: 1,
                                color: '#cb72f1',
                            },
                        ])
                    },
                },
                {
                    name: '上班人数',
                    type: 'pictorialBar',
                    symbolSize: [12, 6],
                    symbolOffset: [7, -3],
                    z: 12,
                    itemStyle: { color: '#cc50f7' },
                    symbolPosition: 'end',
                    data: this.state.onlineData,
                },
                {
                    name: '上班人数',
                    type: 'pictorialBar',
                    symbolSize: [12, 6],
                    symbolOffset: [7, 3],
                    z: 12,
                    itemStyle: { color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                        {
                            offset: 0,
                            color: '#f572d3',
                        },
                        {
                            offset: 1,
                            color: '#cb72f1',
                        },
                    ]) },
                    symbolPosition: 'start',
                    data: this.state.onlineData,
                }
            ],
            
            // legend 对应的 color，实际上柱状图的颜色都是在 itemStyle 里定义的
            color: ['#4caefe', '#2872FC'],
        }


        option && myChart.setOption(option);
    }

    render() {
        return <div id="threeDChart" style={{ width: "100%", height: "100%" }}></div>
    }
}

export default injectIntl(Echarts3dBar)