import React from "react";
import { injectIntl } from "react-intl";
import * as echarts from "echarts";

let myChart;

class EchartsSunburst extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            sumNum: this.props.sumNum
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.chartRefresh);
    }

    componentWillUnmount() {
		window.removeEventListener('resize', this.chartRefresh);
	}

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.setState({
                data: this.props.data,
                sumNum: this.props.sumNum
            }, () => {
                this.initChart();
            })
        }
    }

    chartRefresh = () => {
        setTimeout(() => {
            if (myChart !== null && myChart !== "" && myChart !== undefined) {
                myChart.resize();
            }
        }, 500)
    }

    initChart = () => {
        console.log(this.state.data)
        if (myChart !== null && myChart !== "" && myChart !== undefined) {
            myChart.dispose(); //销毁
        }

        var chartDom = document.getElementById("sunburst");
        myChart = echarts.init(chartDom);
        var option;
        option = {
            series: {
                type: "sunburst",
                data: this.state.data,
                radius: [0, "68%"],
                label: {
                    position: 'inside',
                    rotate: "radial",
                    fontSize: '0.0625rem',    //12px
                    color: '#fff',
                    formatter: params => {
                        return params.name + '  ' + Math.round((params.value / this.state.sumNum * 100) * 100) / 100 + '%'
                    },  
                },
                itemStyle: {
                    borderWidth: 1,
                    borderColor: '#fff'
                },
                levels: [{
                    // 留给数据下钻点的空白配置
                }, {
                    r0: '0',
                    r: '42%',
                    label: {
                        formatter: params => {
                            return params.name
                        },
                    }
                }, {
                    r0: '48%',
                    r: '68%',
                    label: {
                        position: 'outside',
                        rotate: 0,
                        color: '#32AAFC',
                        fontSize: '0.07292rem',    //14px
                        distance: 15,
                        padding: [0, 15]
                    },
                    labelLine: {
                        show: true,
                        length2: 15
                    },
                }]
            },
        };

        option && myChart.setOption(option);
    }

    render() {
        return <div id="sunburst" style={{ width: "100%", height: "100%" }}></div>;
    }
}

export default injectIntl(EchartsSunburst);
